import { InfoCircleFilled } from "@ant-design/icons";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as Sentry from "@sentry/react";
import { Form, Formik, FormikConfig } from "formik";
import { isNil, size } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";

import { RadioButtonField } from "components/form/RadioButtonField";
import { ShareContactBulkMetadata } from "constants/objectMetadata/shareContactBulkMetadata";
import { usePopForm } from "contexts/FormDialogsContext";
import { useUsersDisplay } from "contexts/UserDisplayContext";
import { YupRequiredFieldsProvider } from "contexts/YupRequiredFieldsContext";
import useAuth from "hooks/useAuth";
import { UserLookupFieldMultiple } from "pages/deal/components/LookupField";
import { pluralizeWord } from "pages/deal/utils/deal";
import { formatWholeNumber } from "pages/deal/utils/reporting";
import { getContactAccessQueryKey } from "queries/useContact";
import { dispatch } from "store";
import { openErrorNotification } from "store/reducers/common";
import { ContactAccessRead } from "types/api/deal/contact";
import { ContactShareBulk, EditType } from "types/api/deal/contact_bulk";
import { LookupItem } from "types/api/deal/form";
import { updateContactAccessBulkAsync } from "utils/record_access";

import {
  deriveYupSchemaFromMetadataV2,
  getInitialValues,
  getOnSubmit,
} from "../standard/utils/metadataV2";

interface ShareContactMeta {
  is_filter: boolean;
  record_ids: number[];
  queryParams: URLSearchParams;
  count: number;
}

function isValidMeta(meta: any): meta is ShareContactMeta {
  return (
    !isNil(meta) &&
    typeof meta.is_filter === "boolean" &&
    Array.isArray(meta.record_ids) &&
    meta.queryParams instanceof URLSearchParams &&
    typeof meta.count === "number"
  );
}

interface ShareContactBulkFormikValues {
  edit_type: EditType;
  accessible_by_user_ids: LookupItem[];
}

interface ShareContactBulkFormBaseProps {
  meta: ShareContactMeta;
  changes?: object;
  submitData: (data: any) => Promise<void>;
}

function ShareContactBulkFormBase({
  changes = {},
  submitData,
  meta,
}: ShareContactBulkFormBaseProps) {
  const { user } = useAuth();
  const usersDisplay = useUsersDisplay();
  const [step, setStep] = useState<"form" | "confirmation">("form");
  const popForm = usePopForm();

  const initialValues = useMemo(
    () =>
      getInitialValues<
        ContactAccessRead,
        Partial<ShareContactBulkFormikValues>
      >(ShareContactBulkMetadata, undefined, changes, usersDisplay, user),
    [changes, user, usersDisplay]
  );

  const validationSchema = useMemo(
    () => deriveYupSchemaFromMetadataV2(ShareContactBulkMetadata),
    []
  );

  const realOnSubmit = useMemo(
    () => getOnSubmit(ShareContactBulkMetadata, submitData),
    [submitData]
  );

  const onSubmit: FormikConfig<any>["onSubmit"] = useCallback(
    (values, formikHelpers) => {
      if (step === "form") {
        setStep("confirmation");
        // Wait 1s to unblock the final save button
        setTimeout(() => formikHelpers.setSubmitting(false), 1000);
      } else {
        realOnSubmit(values, formikHelpers);
      }
    },
    [step, realOnSubmit]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={true}
      >
        {(formik) => (
          <YupRequiredFieldsProvider validationSchema={validationSchema}>
            <Form>
              <Dialog open={true} scroll={"paper"} maxWidth={"xs"}>
                <DialogTitle>Share Contacts</DialogTitle>
                <DialogContent dividers>
                  {step === "form" ? (
                    <>
                      <Typography>
                        Let other users access these Contacts based on their
                        permissions.
                      </Typography>
                      <br />
                      <Stack spacing={1}>
                        <Box>
                          <Typography sx={{ fontWeight: 600, pb: 0.5 }}>
                            {
                              ShareContactBulkMetadata.accessible_by_user_ids
                                .displayName
                            }
                          </Typography>
                          <UserLookupFieldMultiple
                            fieldName={
                              ShareContactBulkMetadata.accessible_by_user_ids
                                .fieldName
                            }
                            displayName={
                              ShareContactBulkMetadata.accessible_by_user_ids
                                .displayName
                            }
                            showLabel={false}
                          />
                        </Box>
                        <RadioButtonField
                          fieldName={
                            ShareContactBulkMetadata.edit_type.fieldName
                          }
                          options={[
                            {
                              key: EditType.append,
                              label: "Append to current value(s)",
                            },
                            {
                              key: EditType.replace,
                              label: "Replace current value(s)",
                            },
                          ]}
                          showLabel={false}
                        />
                      </Stack>
                    </>
                  ) : (
                    // Confirmation screen
                    <>
                      <Typography>
                        Please confirm the changes you are about to make.
                      </Typography>
                      <br />
                      <Typography>
                        The following users will{" "}
                        {formik.values.edit_type === EditType.append ? (
                          <>
                            be <b>added</b> to
                          </>
                        ) : (
                          <b>replace</b>
                        )}{" "}
                        the existing access list for{" "}
                        <b>
                          {formatWholeNumber(meta.count)}{" "}
                          {pluralizeWord(meta.count, "contact", "contacts")}
                        </b>
                        .
                      </Typography>
                      <ul>
                        {formik.values.accessible_by_user_ids
                          ? formik.values.accessible_by_user_ids.map(
                              (user: LookupItem) => {
                                return (
                                  <li key={user["key"]}>{user["label"]}</li>
                                );
                              }
                            )
                          : "No users selected"}
                      </ul>
                    </>
                  )}
                </DialogContent>
                <DialogActions>
                  {step === "form" ? (
                    <Grid
                      container
                      style={{ display: "flex" }}
                      alignItems="center"
                    >
                      <Grid item xs sx={{ flexGrow: 1 }}></Grid>
                      <Grid item>
                        {formik.submitCount > 0 && size(formik.errors) ? (
                          <Alert color="error" icon={<InfoCircleFilled />}>
                            Please correct one or more errors on the form.
                          </Alert>
                        ) : null}
                      </Grid>
                      <Grid item xs>
                        <Stack
                          direction="row"
                          justifyContent="flex-end"
                          spacing={2}
                          alignItems="center"
                          sx={{ mr: 1 }}
                        >
                          <Button
                            color="secondary"
                            onClick={popForm}
                            size={"large"}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            onClick={() => formik.handleSubmit()}
                            size={"large"}
                            variant="contained"
                            disabled={formik.isSubmitting}
                          >
                            Next
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  ) : (
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      spacing={2}
                      alignItems="center"
                      sx={{ mr: 1 }}
                    >
                      <Button
                        color="secondary"
                        onClick={popForm}
                        size={"large"}
                      >
                        Cancel
                      </Button>
                      <Button onClick={() => setStep("form")} size={"large"}>
                        Back
                      </Button>
                      <Button
                        type="submit"
                        onClick={() => formik.handleSubmit()}
                        size={"large"}
                        variant="contained"
                        disabled={formik.isSubmitting}
                      >
                        Save
                      </Button>
                    </Stack>
                  )}
                </DialogActions>
              </Dialog>
            </Form>
          </YupRequiredFieldsProvider>
        )}
      </Formik>
    </LocalizationProvider>
  );
}

export interface ShareContactBulkFormProps {
  id?: string;
  changes?: object;
  handleClose: () => void;
  successCallback: any;
  meta?: any;
}

interface ContactAccessBulkUpdate {
  accessible_by_user_ids: number[] | null;
  accessible_by_team_ids: number[] | null;
  edit_type: EditType;
}

export const ShareContactBulkForm = ({
  changes,
  handleClose,
  successCallback,
  meta,
}: ShareContactBulkFormProps) => {
  const queryClient = useQueryClient();

  if (!isValidMeta(meta)) return null;

  const submitData = useCallback(
    async (record: ContactAccessBulkUpdate) => {
      try {
        const updateObj: ContactShareBulk = {
          user_ids: record.accessible_by_user_ids ?? [],
          team_ids: [],
          edit_type: record.edit_type,
          is_filter: meta.is_filter,
          record_ids: meta.record_ids,
        };
        await updateContactAccessBulkAsync(updateObj, meta.queryParams);
        await queryClient.invalidateQueries(getContactAccessQueryKey());
        if (typeof successCallback === "function") successCallback();
        handleClose();
      } catch (error) {
        dispatch(openErrorNotification("Error updating access."));
        Sentry.captureException("Unable to update record access.");
      }
    },
    [meta, handleClose, queryClient, successCallback]
  );

  return (
    <ShareContactBulkFormBase
      submitData={submitData}
      changes={changes}
      meta={meta}
    />
  );
};
