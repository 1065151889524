export enum Product {
  prospects = "prospects",
  deal_view_pro = "deal_view_pro",
  deal_view = "deal_view",
  back_office = "back_office",
}

export enum BaseRoute {
  deals = "deals",
  prospects = "prospects",
  back_office = "back-office",
}

export const ROUTE_PRODUCTS = {
  [BaseRoute.deals]: [Product.deal_view, Product.deal_view_pro],
  [BaseRoute.prospects]: [Product.prospects],
  [BaseRoute.back_office]: [Product.back_office],
};
