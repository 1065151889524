import { useQuery } from "react-query";

import { EntityEnum } from "types/api/deal/enum";
import { AggregateReportLogic } from "types/api/deal/report/report";
import { AggregateTableResponse } from "types/api/deal/report/report_api";
import { dealService } from "utils/axios";

export const getAggregateReportQueryKey = (
  entity: EntityEnum,
  report_logic: AggregateReportLogic,
  queryParams: URLSearchParams
) => ["aggregate_report", entity, report_logic, queryParams.toString()];

export const useAggregateReportQuery = (
  entity: EntityEnum,
  report_logic: AggregateReportLogic,
  queryParams: URLSearchParams
) => {
  const query = useQuery<AggregateTableResponse>({
    queryKey: getAggregateReportQueryKey(entity, report_logic, queryParams),
    queryFn: () =>
      dealService
        .post(`/reporting/aggregate_report/${entity}`, report_logic, {
          params: queryParams,
        })
        .then((response) => {
          return response.data;
        }),
  });

  return query;
};
