import { useQuery } from "react-query";

import { CustomFormEntityEnum } from "types/api/deal/dynamic_form/form_field";
import { FormLayoutRead } from "types/api/deal/dynamic_form/form_layout";
import { dealService } from "utils/axios";

export const getFormLayoutQueryKey = (entity_type: CustomFormEntityEnum) => [
  "form_layout",
  entity_type,
];

export const useFormLayoutQuery = (entity_type: CustomFormEntityEnum) => {
  const query = useQuery<FormLayoutRead>({
    queryKey: getFormLayoutQueryKey(entity_type),
    queryFn: () =>
      dealService.get(`/form_layout/${entity_type}`).then((response) => {
        return response?.data || {};
      }),
  });

  return query;
};
