import { dispatch } from "store";
import { openErrorNotification } from "store/reducers/common";
import { Voucher } from "types/api/deal/voucher";
import { dealService } from "utils/axios";
import { handleRecordActionResponseV4 } from "utils/record";

export async function createVoucherAsync(voucher: Voucher) {
  try {
    const response = await dealService.post(`/voucher/`, voucher);
    handleRecordActionResponseV4(response);
    return response.data;
  } catch (error) {
    dispatch(openErrorNotification("Error creating voucher."));
    throw error;
  }
}

export async function updateVoucherAsync(
  id: string,
  voucher: Partial<Voucher>
) {
  try {
    const response = await dealService.put(`/voucher/${id}`, voucher);
    handleRecordActionResponseV4(response);
    return response.data;
  } catch (error) {
    dispatch(openErrorNotification("Error updating voucher."));
    throw error;
  }
}

export async function deleteVoucherAsync(id: string) {
  try {
    const response = await dealService.delete(`/voucher/${id}`);
    handleRecordActionResponseV4(response);
  } catch (error) {
    dispatch(openErrorNotification("Error deleting voucher."));
    throw error;
  }
}
