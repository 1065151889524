import { useQuery } from "react-query";

import { InvoiceRead, InvoiceReadList } from "types/api/deal/invoice";
import { dealService } from "utils/axios";

export const getInvoiceQueryKey = (invoice_id: string) => [
  "invoice",
  invoice_id,
];

export const useInvoiceQuery = (invoice_id: string) => {
  const query = useQuery<InvoiceRead>({
    queryKey: getInvoiceQueryKey(invoice_id),
    queryFn: () =>
      dealService.get(`/invoice/${invoice_id}`).then((response) => {
        return response.data;
      }),
  });

  return query;
};

export const getInvoicesQueryKey = (queryParams: URLSearchParams) => [
  "invoice_table",
  queryParams.toString(),
];

export const useInvoicesQuery = (queryParams: URLSearchParams) => {
  const query = useQuery<InvoiceReadList>({
    queryKey: getInvoicesQueryKey(queryParams),
    queryFn: () =>
      dealService
        .get(`/invoice/tables/overview`, { params: queryParams })
        .then((response) => {
          return response.data;
        }),
  });

  return query;
};
