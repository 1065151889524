import { Box, Link, Stack, Table, TableBody, Typography } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";

import { handlePreview } from "components/form/standard/utils/navigation";
import MainCard from "components/MainCard";
import { DealStatusChip } from "components/table/field/MenuChip";
import { EmptyTable } from "components/third-party/ReactTable";
import {
  LoadingOverlay,
  ProFeatureOverlay,
} from "pages/deal/sections/charts/ChartOverlay";
import { getBaseQueryOptionsDealService } from "pages/deal/utils/api";
import { formatDateString } from "pages/deal/utils/reporting";
import { startEditingRecord } from "store/reducers/record";
import { ContactRead } from "types/api/deal/contact";
import { DealStatus } from "types/deal";
import { DealOverview } from "types/dealReporting";
import { FormIdentifier, RecordIdentifier } from "types/record";

interface DealPreviewPaneProps {
  contactRecord: ContactRead;
}

function DealCard(props: { deal: DealOverview }) {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <MainCard>
      <Stack spacing={0.5}>
        <Link
          sx={{ cursor: "pointer", fontWeight: 600 }}
          onClick={() => {
            handlePreview(
              props.deal.id as number,
              RecordIdentifier.Deal,
              searchParams,
              setSearchParams
            );
          }}
        >
          {props.deal.name}
        </Link>

        <Stack direction={"row"} alignItems={"baseline"}>
          <Typography variant="body1">Status:</Typography>
          {"\u00A0"}
          <DealStatusChip
            value={props.deal.status as DealStatus}
            recordId={props.deal.id as number}
          />
        </Stack>
        <Typography variant="body1">
          Close date: {formatDateString(props.deal.close_date_effective)}
        </Typography>
      </Stack>
    </MainCard>
  );
}

const DealPreviewPane = ({ contactRecord }: DealPreviewPaneProps) => {
  const queryOptions = useMemo(
    () =>
      getBaseQueryOptionsDealService<DealOverview[]>(
        `/tables/contact/${contactRecord?.id}`
      ),
    [contactRecord?.id]
  );
  const { data: dealOverviewData = [], isLoading } =
    useQuery<DealOverview[]>(queryOptions);

  const numDeals = useMemo(() => _.size(dealOverviewData), [dealOverviewData]);

  return (
    <Box>
      <LoadingOverlay showChart={!isLoading} height={"100%"} width={"100%"}>
        <MainCard
          title={`Deals (${numDeals})`}
          secondary={
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                const incomingChanges = {
                  contact_id: contactRecord.id,
                  contact: {
                    key: contactRecord.id,
                    label: contactRecord.full_name,
                  },
                };
                startEditingRecord(
                  undefined,
                  FormIdentifier.DealViewProForm,
                  incomingChanges
                );
              }}
            >
              + Add
            </Link>
          }
        >
          <ProFeatureOverlay>
            {!!dealOverviewData && dealOverviewData.length ? (
              <Stack sx={{ width: 1 }} spacing={1.5}>
                {_.map(dealOverviewData, (x) => (
                  <DealCard deal={x} />
                ))}
              </Stack>
            ) : (
              <Table>
                <TableBody>
                  <EmptyTable msg={"No associated Deals found."} colSpan={10} />
                </TableBody>
              </Table>
            )}
          </ProFeatureOverlay>
        </MainCard>
      </LoadingOverlay>
    </Box>
  );
};

export default DealPreviewPane;
