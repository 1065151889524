import * as Sentry from "@sentry/browser";
import jwtDecode from "jwt-decode";
import _, { get } from "lodash";
import React, { createContext, useEffect, useReducer } from "react";
import { useQueryClient } from "react-query";

import { LOGIN, LOGOUT, REFRESH, REGISTER } from "store/reducers/actions";
import authReducer from "store/reducers/auth";
import { setSSODetailsData, setSSOFlag } from "store/reducers/registrationData";
import { AccessTokenRead } from "types/api/user_management/access_token";
import {
  AuthProps,
  JWTContextType,
  OpenID,
  UserRegister,
  UserSignOutReason,
} from "types/auth";
import { axiosUserServices } from "utils/axios";
import { captureUIEvent } from "utils/eventCapture";

import Loader from "../components/Loader";

// constant
const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const queryClient = useQueryClient();
  const refresh = () => {
    axiosUserServices
      .post("/user/refresh/")
      .then((response) => {
        if (response.status < 500 && response.status >= 400) throw 401;
        const { token_header_payload }: { token_header_payload: string } =
          response.data.data;
        const userProfile = jwtDecode<AccessTokenRead>(token_header_payload);

        dispatch({
          type: REFRESH,
          payload: {
            isInitialized: true,
            isLoggedIn: true,
            user: userProfile,
          },
        });

        // @ts-ignore
        if (window.posthog) {
          // @ts-ignore
          window.posthog.identify(`${userProfile.user_id}`, {
            email: userProfile.username,
            first_name: userProfile.first_name,
            last_name: userProfile.last_name,
          });

          Sentry.setUser({
            id: userProfile.user_id,
            username: userProfile.username,
            first_name: userProfile.first_name,
            last_name: userProfile.last_name,
            fullName: `${userProfile.first_name} ${userProfile.last_name}`,
          });
        }
      })
      .catch(() => {
        captureUIEvent("user_signed_out", {
          reason: UserSignOutReason.RefreshFailed,
        });
        logout();
      });
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = async (
    email: string,
    password: string,
    authentication_token: string | null
  ) => {
    return axiosUserServices
      .postForm(
        "/user/login/",
        {
          password,
          username: _.toLower(email),
        },
        { params: { token: authentication_token } }
      )
      .then((response) => {
        if (response.data && response.data.error) {
          throw response.data.error;
        }
        const { token_header_payload }: { token_header_payload: string } =
          response.data.data;
        const userProfile = jwtDecode<AccessTokenRead>(token_header_payload);

        dispatch({
          type: LOGIN,
          payload: {
            isInitialized: true,
            isLoggedIn: true,
            user: userProfile,
          },
        });
      });
  };

  const resendActivationLink = async (data: { username: string }) => {
    return axiosUserServices
      .post("/user/resend-activation-link/", data)
      .then((response) => {
        if (response.status === 200) {
          window.location.href = "/check-mail";
        }
        if (response.status >= 400 && response.status < 500) throw 401;
      });
  };

  const forgotPassword = async (data: { username: string }) => {
    return axiosUserServices.post("/user/forgot/", data).then((response) => {
      if (500 > response.status && response.status >= 400) throw 401;
      const user = response.data.data;
      dispatch({
        type: REGISTER,
        payload: {
          user,
          isInitialized: true,
          isLoggedIn: false,
        },
      });
    });
  };

  const resetPassword = async (
    data: { password: string },
    token: string | null
  ) => {
    return axiosUserServices
      .post("/user/reset-password/", data, { params: { token } })
      .then((response) => {
        if (500 > response.status && response.status >= 400) throw 401;
      });
  };

  const changePassword = async (data: { password: string }) => {
    return axiosUserServices
      .post("/user/change-password/", data)
      .then((response) => {
        if (500 > response.status && response.status >= 400) throw 401;
      });
  };

  const logout = () => {
    // Remove react-query queries on logout. This helps with the case when a user logs out and logs in as another user.
    queryClient.removeQueries();

    dispatch({ type: LOGOUT });
    axiosUserServices.post("/user/logout/");
  };

  const register = (data: UserRegister, product_id: string) => {
    return axiosUserServices
      .post("/user/register/", data, {
        params: {
          product_id,
        },
      })
      .then((response) => {
        if (500 > response.status && response.status >= 400) throw 401;
        const {
          user,
          token_header,
        }: { user: AccessTokenRead; token_header: string } = response.data.data;
        dispatch({
          type: REGISTER,
          payload: {
            user,
            isInitialized: true,
            isLoggedIn: false,
            token_header_payload: jwtDecode(token_header),
            token_header: token_header,
          },
        });
        return token_header;
      });
  };

  const sso_register = async (user: AccessTokenRead, token_header: string) => {
    dispatch({
      type: REGISTER,
      payload: {
        user,
        isInitialized: true,
        isLoggedIn: false,
        token_header_payload: jwtDecode(token_header),
        token_header: token_header,
      },
    });
    return token_header;
  };

  const sso_login = async (token_header_payload: string) => {
    const userProfile = jwtDecode<AccessTokenRead>(token_header_payload);

    dispatch({
      type: LOGIN,
      payload: {
        isInitialized: true,
        isLoggedIn: true,
        user: userProfile,
      },
    });
  };

  const sso_identify = (
    user_openid: OpenID,
    code: string | null,
    client_info: string | null,
    state: string | null,
    session_state: string | null
  ) => {
    setSSOFlag(true);
    setSSODetailsData({
      first_name: user_openid?.first_name ?? "",
      last_name: user_openid?.last_name ?? "",
      username: user_openid?.email ?? "",
      code,
      client_info,
      state,
      session_state,
    });
  };

  const checkPermission = (_path: string) => {
    return _.get(state.user?.permissions, _path, false);
  };

  const checkPermissions = (permissions: string[]) => {
    return permissions.some((permission) =>
      get(state.user?.permissions, permission)
    );
  };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider
      //@ts-ignore
      value={{
        ...state,
        login,
        logout,
        refresh,
        register,
        sso_register,
        sso_login,
        sso_identify,
        resendActivationLink,
        forgotPassword,
        resetPassword,
        changePassword,
        checkPermission,
        checkPermissions,
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

export default JWTContext;
