import { FilterAutocomplete } from "components/filter/FilterBar/FilterComponents/Autocomplete/FilterAutocomplete";
import { FilterAutocompleteDealOption } from "components/filter/FilterBar/FilterComponents/Autocomplete/FilterAutocompleteDealOption";
import { FilterAutocompleteLookup } from "components/filter/FilterBar/FilterComponents/Autocomplete/FilterAutocompleteLookup";
import { FilterAutocompleteStageLookup } from "components/filter/FilterBar/FilterComponents/Autocomplete/FilterAutocompleteStageLookup";
import { FilterAutocompleteDealUserLookup } from "components/filter/FilterBar/FilterComponents/Autocomplete/FilterAutocompleteUserLookup";
import { FilterDateRange } from "components/filter/FilterBar/FilterComponents/FilterDateRange";
import { FilterSelect } from "components/filter/FilterBar/FilterComponents/FilterSelect";
import { DealMetadata } from "constants/objectMetadata/dealMetadata";
import { isFieldVisibleOrgPreferences } from "contexts/DealOrgPreferencesContext";
import { DateRangeFilterTypeSelect } from "pages/deal/components/DateRangeFilterTypeSelect";
import {
  BROKER_ROLE_TOGGLE_OPTIONS,
  DEAL_STATUS_OPTIONS,
  DEAL_TYPE_OPTIONS,
  IS_SHARED_OPTIONS,
} from "pages/deal/constants/deal_enums";
import { DealField } from "types/deal";
import { FilterBarLayout, FilterMetadata } from "types/filter";

export const DealFilterMetadata: Record<string, FilterMetadata> = {
  date_range_type: {
    key: "date_range_type",
    component: DateRangeFilterTypeSelect,
    isLiveUpdate: true,
  },
  deal_type: {
    key: "deal_type",
    label: DealMetadata.deal_type.displayName,
    component: FilterAutocomplete,
    options: DEAL_TYPE_OPTIONS,
  },
  broker: {
    key: "broker",
    label: "Broker",
    component: FilterAutocompleteDealUserLookup,
  },
  date_range: {
    key: "date_range",
    label: "Date Range",
    component: FilterDateRange,
  },
  status: {
    key: "status",
    label: DealMetadata.status.displayName,
    component: FilterSelect,
    options: DEAL_STATUS_OPTIONS,
    multiple: true,
  },
  asset_type_id: {
    key: "asset_type_id",
    label: DealMetadata.asset_type.displayName,
    component: FilterAutocompleteDealOption,
    optionFieldName: "asset_type",
  },
  broker_role: {
    key: "broker_role",
    label: "Broker Role",
    component: FilterSelect,
    options: BROKER_ROLE_TOGGLE_OPTIONS,
  },
  client_industry_id: {
    key: "client_industry_id",
    label: DealMetadata.client_industry.displayName,
    component: FilterAutocompleteDealOption,
    optionFieldName: "client_industry",
    visibility: (dealOrgPreferences) =>
      isFieldVisibleOrgPreferences(
        dealOrgPreferences,
        DealField.CLIENT_INDUSTRY
      ),
  },
  source_type_id: {
    key: "source_type_id",
    label: DealMetadata.source_type.displayName,
    component: FilterAutocompleteDealOption,
    optionFieldName: "source_type",
    visibility: (dealOrgPreferences) =>
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.SOURCE_TYPE),
  },
  contact_id: {
    key: "contact_id",
    component: FilterAutocompleteLookup,
    label: DealMetadata.contact.displayName,
    url: "/form/lookup/contact?limit=-1",
    visibility: (dealOrgPreferences) =>
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.CONTACT),
  },
  property_name_id: {
    key: "property_name_id",
    label: DealMetadata.property_name.displayName,
    component: FilterAutocompleteDealOption,
    optionFieldName: "property_name",
    visibility: (dealOrgPreferences) =>
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.PROPERTY_NAME),
  },
  stage: {
    key: "stage_id",
    label: DealMetadata.stage.displayName,
    component: FilterAutocompleteStageLookup,
    visibility: (dealOrgPreferences) =>
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.STAGE),
  },
  shared_deal: {
    key: "shared_deal",
    label: "Shared/Solo",
    component: FilterSelect,
    options: IS_SHARED_OPTIONS,
    visibility: (dealOrgPreferences) =>
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.SHARED_BROKER),
  },
  submarket_id: {
    key: "submarket_id",
    label: DealMetadata.submarket.displayName,
    component: FilterAutocompleteDealOption,
    optionFieldName: "submarket",
    visibility: (dealOrgPreferences) =>
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.SUBMARKET),
  },
  tenant_name_id: {
    key: "tenant_name_id",
    label: DealMetadata.tenant_name.displayName,
    component: FilterAutocompleteDealOption,
    optionFieldName: "tenant_name",
    visibility: (dealOrgPreferences) =>
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.TENANT_NAME),
  },
};

export const DealFilterBarLayout: FilterBarLayout = {
  mainFilters: [
    { filter: DealFilterMetadata.date_range_type, align: "right" },
    { filter: DealFilterMetadata.deal_type },
    { filter: DealFilterMetadata.broker },
    { filter: DealFilterMetadata.date_range },
    { filter: DealFilterMetadata.status },
    { filter: DealFilterMetadata.asset_type_id },
  ],
  additionalFilters: [
    { filter: DealFilterMetadata.broker_role },
    { filter: DealFilterMetadata.client_industry_id },
    { filter: DealFilterMetadata.source_type_id },
    { filter: DealFilterMetadata.contact_id },
    { filter: DealFilterMetadata.property_name_id },
    { filter: DealFilterMetadata.stage },
    { filter: DealFilterMetadata.shared_deal },
    { filter: DealFilterMetadata.submarket_id },
    { filter: DealFilterMetadata.tenant_name_id },
  ],
};
