import { Grid } from "@mui/material";

import {
  ReadOnlyLookupField,
  ReadOnlyTextFieldString,
} from "./ReadOnlyTextFieldString";

export const ReadOnlyAddress = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {/* TODO: Search icon and styles */}
        <ReadOnlyLookupField label={"Address Search"} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ReadOnlyTextFieldString label={"Street address"} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ReadOnlyTextFieldString label={"Apt / suite"} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ReadOnlyTextFieldString label={"City"} />
      </Grid>
      <Grid item xs={12} sm={2}>
        {/* TODO: Dropdown styles */}
        <ReadOnlyTextFieldString label={"State"} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <ReadOnlyTextFieldString label={"Zip"} />
      </Grid>
    </Grid>
  );
};
