import { OrgPreferencesRead } from "types/api/deal/preferences";
import { AccessTokenRead } from "types/api/user_management/access_token";

export interface FieldMetadataV3 {
  // Yup schema used in the formik object for data validation
  _schema: object | ((orgPreferences: OrgPreferencesRead) => object);
  // Name seen above the input field
  displayName: string;
  // Name used in the formik object to set and retrieve values. Should be snake_case
  fieldName: string;
  // Initial value of the field when created
  initialValue: any | ((user: AccessTokenRead) => any);
  submitHandler?: (value: any) => any;
  loadHandler?: (
    value: any,
    userOptionsDictionary: Record<string, string>
  ) => any;
  // Name of the backend field that is meant to receive submitted data
  submitFieldName?: string;
  // Flag for if the field is virtual or not. If so, don't submit it to the backend on save.
  virtual?: boolean;
}

export type MetadataV3 = FieldMetadataV3 | ObjectMetadataV3;
export interface ObjectMetadataV3 {
  [key: string]: MetadataV3;
}

// Type guard function to check if the item is FieldMetadataV3
export function isFieldMetadataV3(item: MetadataV3): item is FieldMetadataV3 {
  return !!item && "_schema" in item && item._schema !== undefined;
}

/**
 * Property specific form types
 */
export interface PropertyFieldMetadata extends FieldMetadataV3 {
  component?: (props: {
    fieldName: string;
    displayName?: string;
    required?: boolean;
  }) => JSX.Element;
  formatForDisplay?: (value: any) => string;
  formSection?: "property_information" | "transaction_information";
}

type PropertyMetadataV3 = PropertyFieldMetadata | PropertyObjectMetadataV3;
export interface PropertyObjectMetadataV3 {
  [key: string]: PropertyMetadataV3;
}

/**
 * Invoice specific form types
 */
export interface InvoiceFieldMetadata extends FieldMetadataV3 {
  component?: (props: {
    fieldName: string;
    displayName?: string;
  }) => JSX.Element;
  formatForDisplay?: (value: any) => string;
}

type InvoiceMetadataV3 = InvoiceFieldMetadata | InvoiceObjectMetadataV3;
export interface InvoiceObjectMetadataV3 {
  [key: string]: InvoiceMetadataV3;
}

/**
 * Voucher specific form types
 */
export interface VoucherFieldMetadata extends FieldMetadataV3 {
  component?: (props: {
    fieldName: string;
    displayName?: string;
  }) => JSX.Element;
  formatForDisplay?: (value: any) => string;
}

type VoucherMetadataV3 = VoucherFieldMetadata | VoucherObjectMetadataV3;
export interface VoucherObjectMetadataV3 {
  [key: string]: VoucherMetadataV3;
}
