import { useQuery } from "react-query";

import { ContactAccessRead, ContactRead } from "types/api/deal/contact";
import { ContactOverviewList } from "types/api/deal/contactTables";
import { dealService } from "utils/axios";

export const getContactQueryKey = (id: string) => ["contact", id];

export const useContactQuery = (id: string) => {
  const query = useQuery<ContactRead>({
    queryKey: getContactQueryKey(id),
    queryFn: () =>
      dealService.get(`/contact/${id}`).then((response) => {
        return response?.data?.data || {};
      }),
  });

  return query;
};

export const getContactAccessQueryKey = (id?: string) =>
  !!id ? ["contact/access", id] : ["contact/access"];

export const useContactAccessQuery = (id: string) => {
  const query = useQuery<ContactAccessRead>({
    queryKey: getContactAccessQueryKey(id),
    queryFn: () =>
      dealService.get(`/contact/access/${id}`).then((response) => {
        return response?.data || {};
      }),
  });

  return query;
};

export const getContactsQueryKey = (queryParams: URLSearchParams) => [
  "contact_table",
  queryParams.toString(),
];

export const useContactsQuery = (queryParams: URLSearchParams) => {
  const query = useQuery<ContactOverviewList>({
    queryKey: getContactsQueryKey(queryParams),
    queryFn: () =>
      dealService
        .get(`/contact/tables/overview/v2/`, { params: queryParams })
        .then((response) => {
          return response.data.data;
        }),
  });

  return query;
};
