import * as React from "react";
import { useMemo } from "react";
import { useQuery } from "react-query";

import { FilterAutocomplete } from "components/filter/FilterBar/FilterComponents/Autocomplete/FilterAutocomplete";
import { FilterAutocompleteLookupProps } from "components/filter/FilterBar/FilterComponents/Autocomplete/FilterAutocompleteLookup";
import useAuth from "hooks/useAuth";
import { getBaseQueryOptionsUserService } from "pages/deal/utils/api";
import { SimpleOption } from "types/api/deal/form";
import { LookupUserScope } from "types/api/user_management/organization";
import { PermissionSet } from "types/permission";

enum LookupUserOrderBy {
  name = "name",
  group = "group",
}

export function FilterAutocompleteDealUserLookup(
  props: FilterAutocompleteLookupProps
) {
  const { label, value, onChange, sx } = props;
  const { checkPermission } = useAuth();
  const scope = useMemo(() => {
    let scope = LookupUserScope.individual;

    if (checkPermission(PermissionSet.deal_all_viewer)) {
      scope = LookupUserScope.organization;
    } else if (checkPermission(PermissionSet.deal_team_viewer)) {
      scope = LookupUserScope.team;
    }

    return scope;
  }, [checkPermission]);

  const queryOptions = getBaseQueryOptionsUserService<Array<SimpleOption>>(
    `/organization/lookup/user?scope=${scope}&order_by=${LookupUserOrderBy.group}&include_other=true`
  );
  const { data = [] } = useQuery<Array<SimpleOption>>(queryOptions);

  return (
    <FilterAutocomplete
      label={label}
      value={value}
      onChange={onChange}
      options={data}
      sx={sx}
      grouped
    />
  );
}

export function FilterAutocompleteContactUserLookup(
  props: FilterAutocompleteLookupProps
) {
  const { label, value, onChange, sx } = props;
  const { checkPermission } = useAuth();
  const scope = useMemo(() => {
    let scope = LookupUserScope.individual;

    if (checkPermission(PermissionSet.contact_all_viewer)) {
      scope = LookupUserScope.organization;
    } else if (checkPermission(PermissionSet.contact_team_viewer)) {
      scope = LookupUserScope.team;
    }

    return scope;
  }, [checkPermission]);

  const queryOptions = getBaseQueryOptionsUserService<Array<SimpleOption>>(
    `/organization/lookup/user?scope=${scope}&order_by=${LookupUserOrderBy.group}`
  );
  const { data = [] } = useQuery<Array<SimpleOption>>(queryOptions);

  return (
    <FilterAutocomplete
      label={label}
      value={value}
      onChange={onChange}
      options={data}
      sx={sx}
      grouped
    />
  );
}
