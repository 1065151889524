import { Button, Typography } from "@mui/material";

import BrowserError from "components/illustrations/BrowserError";

export default function NotFound() {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "16px",
      }}
      role="alert"
    >
      <BrowserError style={{ marginBottom: "16px" }} />
      <Typography variant="h2">404! Page not found.</Typography>
      <Button
        variant="outlined"
        color="info"
        onClick={() => {
          window.location.href = "/";
        }}
        style={{ marginTop: "16px" }}
      >
        Return to home
      </Button>
    </div>
  );
}
