import config from "config";
import { checkLicense } from "hooks/useCheckLicense";
import { AccessTokenRead } from "types/api/user_management/access_token";
import { BaseRoute, ROUTE_PRODUCTS } from "types/license";

export const getDefaultRoute = (user: AccessTokenRead) => {
  // Check licenses in order of priority for navigation
  if (checkLicense(user, ROUTE_PRODUCTS[BaseRoute.prospects])) {
    return `/${BaseRoute.prospects}/dashboard`;
  } else if (checkLicense(user, ROUTE_PRODUCTS[BaseRoute.deals])) {
    return `/${BaseRoute.deals}/overview`;
  } else {
    return config.defaultPath;
  }
};
