import { Dialog, DialogContent, DialogTitle, Grid, Stack } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Form, Formik } from "formik";
import { get, reduce, toNumber } from "lodash";
import { useCallback, useMemo } from "react";
import { useQueryClient } from "react-query";
import * as Yup from "yup";

import { DatePicker } from "components/form/DatePicker";
import { FormDialogActions } from "components/form/standard/FormDialogActions";
import { formatDateForDisplay } from "components/form/standard/utils/formatting";
import {
  deriveYupSchemaFromMetadataV3,
  getInitialValuesV3,
  getOnSubmitV3,
} from "components/form/standard/utils/metadataV3";
import { TextFieldCurrency } from "components/form/TextFieldCurrency";
import {
  TextFieldString,
  TextFieldStringMultiline,
} from "components/form/TextFieldString";
import {
  TextFieldTwoDecimal,
  TextFieldWholeNumber,
} from "components/form/TextFieldWholeNumber";
import { CheckboxField } from "components/form_v2/CheckboxField";
import { PercentField } from "components/form_v2/PercentField";
import MainCard from "components/MainCard";
import { PropertyBaseMetadata } from "constants/objectMetadata/propertyMetadata";
import { usePushForm } from "contexts/FormDialogsContext";
import { useUsersDisplay } from "contexts/UserDisplayContext";
import {
  useIsFieldRequired,
  YupRequiredFieldsProvider,
} from "contexts/YupRequiredFieldsContext";
import useAuth from "hooks/useAuth";
import { dateSubmitHandler, submitCurrency } from "pages/deal/utils/deal_form";
import { formatCurrency } from "pages/deal/utils/reporting";
import { useFormFieldDictQuery } from "queries/useFormField";
import { useFormLayoutQuery } from "queries/useFormLayout";
import {
  getPropertyRecordQueryKey,
  usePropertyRecordQuery,
} from "queries/usePropertyRecord";
import { RecordActionResponse } from "types/api/deal/api";
import {
  CustomDataTypeEnum,
  CustomFormEntityEnum,
  FormFieldRead,
} from "types/api/deal/dynamic_form/form_field";
import { FormLayoutRead } from "types/api/deal/dynamic_form/form_layout";
import { PropertyCreate, PropertyRead } from "types/api/deal/property";
import {
  isFieldMetadataV3,
  PropertyFieldMetadata,
  PropertyObjectMetadataV3,
} from "types/standardFormV3";
import { createPropertyAsync, updatePropertyAsync } from "utils/property";

interface PropertyFormikValues {
  id?: string;
  name: string;
}

const getFieldMetadataFromDataType = (
  dataType: CustomDataTypeEnum,
  fieldName: string,
  displayName: string
): PropertyFieldMetadata => {
  const map = {
    text: {
      _schema: Yup.string().nullable(),
      loadHandler: (x: any) => x,
      submitHandler: (x: any) => x,
      initialValue: "",
      component: TextFieldString,
    },
    multiline_text: {
      _schema: Yup.string().nullable(),
      loadHandler: (x: any) => x,
      submitHandler: (x: any) => x,
      initialValue: "",
      component: TextFieldStringMultiline,
    },
    whole_number: {
      _schema: Yup.number()
        .min(0, `${displayName} must be positive.`)
        .nullable()
        .typeError(`${displayName} must be a number.`),
      loadHandler: (x: any) => x,
      submitHandler: (x: any) => x,
      initialValue: null,
      component: TextFieldWholeNumber,
    },
    decimal_number: {
      _schema: Yup.number()
        .min(0, `${displayName} must be positive.`)
        .nullable()
        .typeError(`${displayName} must be a number.`),
      loadHandler: (x: any) => x,
      submitHandler: (x: any) => x,
      initialValue: null,
      component: TextFieldTwoDecimal,
    },
    percent_number: {
      initialValue: null,
      _schema: Yup.number()
        .min(0, `${displayName} must be positive.`)
        .max(1, `${displayName} cannot be greater than 100%.`)
        .nullable()
        .typeError(`${displayName} must be a number.`),
      component: PercentField,
    },
    currency: {
      initialValue: null,
      _schema: Yup.number()
        .min(0, `${displayName} must be positive.`)
        .nullable()
        .typeError(`${displayName} must be a number.`),
      component: TextFieldCurrency,
      submitHandler: submitCurrency,
      formatForDisplay: formatCurrency,
    },
    date: {
      initialValue: null,
      _schema: Yup.date()
        .nullable()
        .typeError(`${displayName} must be a date.`),
      component: DatePicker,
      submitHandler: dateSubmitHandler,
      formatForDisplay: formatDateForDisplay,
    },
    boolean: {
      initialValue: false,
      _schema: Yup.boolean().nullable(),
      component: CheckboxField,
    },
  };

  return { fieldName: fieldName, displayName: displayName, ...map[dataType] };
};

interface PropertyFormProps {
  record?: PropertyRead;
  changes?: Partial<PropertyFormikValues>;
  submitData: (data: any) => Promise<void>;
  formLayout: FormLayoutRead;
  formFields: Record<string, FormFieldRead>;
}

function PropertyForm({
  record,
  changes = {},
  submitData,
  formLayout,
  formFields,
}: PropertyFormProps) {
  const PropertyMetadata: PropertyObjectMetadataV3 = useMemo(
    () => ({
      ...PropertyBaseMetadata,
      additional_data: reduce(
        formFields,
        (acc, value) => ({
          ...acc,
          [value.name]: getFieldMetadataFromDataType(
            value.data_type,
            value.name,
            value.display_name
          ),
        }),
        {}
      ),
    }),
    [formFields]
  );

  return (
    <PropertyFormBase
      submitData={submitData}
      formLayout={formLayout}
      changes={changes}
      record={record}
      metadata={PropertyMetadata}
    />
  );
}

interface PropertyFormBaseProps {
  record?: PropertyRead;
  changes?: Partial<PropertyFormikValues>;
  submitData: (data: any) => Promise<void>;
  formLayout: FormLayoutRead;
  metadata: PropertyObjectMetadataV3;
}

function PropertyFormBase({
  record,
  changes = {},
  submitData,
  formLayout,
  metadata,
}: PropertyFormBaseProps) {
  const { user } = useAuth();
  const usersDisplay = useUsersDisplay();

  const initialValues = useMemo(
    () =>
      getInitialValuesV3<PropertyRead, Partial<PropertyFormikValues>>(
        metadata,
        record,
        changes,
        usersDisplay,
        user
      ),
    [changes, metadata, record, user, usersDisplay]
  );

  const validationSchema = useMemo(
    () => deriveYupSchemaFromMetadataV3(metadata),
    [metadata]
  );

  const onSubmit = useMemo(
    () => getOnSubmitV3(metadata, submitData),
    [metadata, submitData]
  );

  const DynamicFieldComponent = useMemo(
    () =>
      ({
        fieldName,
        columnWidth,
      }: {
        fieldName: string;
        columnWidth: number;
      }) => {
        const field = get(metadata, fieldName);
        const required = useIsFieldRequired(fieldName);
        if (!isFieldMetadataV3(field)) return null;

        const { component: FieldComponent, displayName } = field;

        if (!FieldComponent) return null;

        return (
          <Grid item xs={12} sm={columnWidth}>
            <FieldComponent
              fieldName={fieldName}
              displayName={displayName}
              required={required}
            />
          </Grid>
        );
      },
    [metadata]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={true}
      >
        <YupRequiredFieldsProvider validationSchema={validationSchema}>
          <Form>
            <Dialog open={true} scroll={"paper"} maxWidth={"md"}>
              <DialogTitle>{record ? "Edit" : "New"} Property</DialogTitle>
              <DialogContent dividers>
                <Stack spacing={2}>
                  {formLayout.layout.map((section, sectionIndex) => (
                    <MainCard
                      title={section.name}
                      key={`section-${sectionIndex}`}
                    >
                      <Grid container spacing={2}>
                        {section.content.map((item) => (
                          <DynamicFieldComponent
                            columnWidth={item.column_width}
                            fieldName={
                              item.field_type === "default"
                                ? `${item.field_name}`
                                : `additional_data.${item.field_name}`
                            }
                            key={`field-${item.field_name}`}
                          />
                        ))}
                      </Grid>
                    </MainCard>
                  ))}
                </Stack>
              </DialogContent>
              <FormDialogActions />
            </Dialog>
          </Form>
        </YupRequiredFieldsProvider>
      </Formik>
    </LocalizationProvider>
  );
}

interface PropertyEditFormProps {
  id: string;
  changes?: Partial<PropertyFormikValues>;
  handleClose: () => void;
  successCallback: (actionList: RecordActionResponse[]) => void;
}

const PropertyEditForm = ({
  id,
  changes,
  handleClose,
  successCallback,
}: PropertyEditFormProps) => {
  const pushForm = usePushForm();
  const { data: formLayout } = useFormLayoutQuery(
    CustomFormEntityEnum.property
  );
  const { data: formFields } = useFormFieldDictQuery(
    CustomFormEntityEnum.property
  );

  const queryClient = useQueryClient();

  const submitData = useCallback(
    async (record: PropertyCreate) => {
      const responseObj = await updatePropertyAsync(id, record, pushForm);
      await queryClient.invalidateQueries(getPropertyRecordQueryKey(id));
      await queryClient.invalidateQueries(["property_table"]);
      successCallback(responseObj);
      handleClose();
    },
    [handleClose, id, pushForm, queryClient]
  );

  const { data } = usePropertyRecordQuery(id);

  return !!formLayout && !!formFields && !!data ? (
    <PropertyForm
      submitData={submitData}
      formLayout={formLayout}
      formFields={formFields}
      changes={changes}
      record={data}
    />
  ) : null;
};

interface PropertyCreateFormProps {
  changes?: Partial<PropertyFormikValues>;
  handleClose: () => void;
  successCallback: (actionList: RecordActionResponse[]) => void;
}

const PropertyCreateForm = ({
  changes,
  handleClose,
  successCallback,
}: PropertyCreateFormProps) => {
  const pushForm = usePushForm();
  const { data: formLayout } = useFormLayoutQuery(
    CustomFormEntityEnum.property
  );
  const { data: formFields } = useFormFieldDictQuery(
    CustomFormEntityEnum.property
  );

  const queryClient = useQueryClient();

  const submitData = useCallback(
    async (record: PropertyCreate) => {
      const responseObj = await createPropertyAsync(record, pushForm);
      await queryClient.invalidateQueries(["property_table"]);
      successCallback(responseObj);
      handleClose();
    },
    [handleClose, pushForm, queryClient]
  );

  return !!formLayout && !!formFields ? (
    <PropertyForm
      submitData={submitData}
      formLayout={formLayout}
      formFields={formFields}
      changes={changes}
    />
  ) : null;
};

const PropertyFormPage = ({
  id,
  handleClose,
  successCallback,
}: {
  id?: string;
  handleClose: () => void;
  successCallback: (actionList: RecordActionResponse[]) => void;
}) => {
  return id ? (
    <PropertyEditForm
      id={id}
      handleClose={handleClose}
      successCallback={successCallback}
    />
  ) : (
    <PropertyCreateForm
      handleClose={handleClose}
      successCallback={successCallback}
    />
  );
};

export default PropertyFormPage;
