import { Theme } from "@mui/material/styles";

// ==============================|| OVERRIDES - ICON BUTTON ||============================== //

export default function StepLabel() {
  return {
    MuiStepLabel: {
      styleOverrides: {
        root: {
          padding: "4px 0",
        },
        label: {
          fontSize: "14px",
          fontWeight: "600 !important",
        },
        iconContainer: {
          fontWeight: "bold",
        },
      },
    },
  };
}
