import {
  Button,
  Divider,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { AxiosResponse } from "axios/index";
import { useFormik } from "formik";
import React from "react";
import { useQuery as useQueryAPI } from "react-query";
import * as Yup from "yup";

import GoogleLightDisabled from "assets/images/icons/google/web_light_sq_SI@2x.png";
import GoogleLightNormal from "assets/images/icons/google/web_light_sq_SI@2x.png";
import SignInWithMicrosoft from "assets/images/icons/ms-symbollockup_signin_light.svg";
import LoadingButton from "components/@extended/LoadingButton";
import { registrationSteps } from "pages/auth/constants/registration";
import { RootState, useSelector } from "store";
import {
  setSSOFlag,
  setUserDetailsData,
} from "store/reducers/registrationData";
import { RegistrationStepProps } from "types/authRegister";
import { axiosUserServices } from "utils/axios";

const validationSchema = Yup.object({
  first_name: Yup.string().max(255).required("First Name is required"),
  last_name: Yup.string().max(255).required("Last Name is required"),
  username: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
  company: Yup.string().max(255),
});

export const UserDetailsForm = ({ handleNext }: RegistrationStepProps) => {
  const userDetailsData = useSelector(
    (state: RootState) => state.registrationData.userDetailsData
  );

  const navigate_to = "/register";

  const {
    data: microsoftSessionLink,
    isLoading: microsoftSessionLinkIsLoading,
  } = useQueryAPI({
    queryKey: ["microsoftSessionLink"],
    queryFn: () =>
      axiosUserServices
        .get("/sso/microsoft/login/", {
          params: { redirect_url: navigate_to, login_stage: "register" },
        })
        .then((response) => response.data),
    retry: 3,
    staleTime: Infinity,
  });

  const { data: googleSessionLink, isLoading: googleSessionLinkIsLoading } =
    useQueryAPI({
      queryKey: ["googleSessionLink"],
      queryFn: () =>
        axiosUserServices
          .get("/sso/google/login/", {
            params: { redirect_url: navigate_to, login_stage: "register" },
          })
          .then((response: AxiosResponse) => response.data),
      retry: 3,
      staleTime: Infinity,
    });

  const formik = useFormik({
    initialValues: {
      first_name: userDetailsData?.first_name,
      last_name: userDetailsData?.last_name,
      username: userDetailsData?.username,
      company: userDetailsData?.company,
    },
    validationSchema,
    onSubmit: (values, { setFieldError, setSubmitting }) => {
      setSubmitting(true);
      axiosUserServices
        .get(`/user/exists/`, { params: { username: values.username } })
        .then((response) => {
          // If username does not exist, proceed
          if (response.data === false) {
            setUserDetailsData({
              first_name: values.first_name,
              last_name: values.last_name,
              username: values.username,
              company: values.company,
            });
            setSSOFlag(false);
            handleNext();
          } else {
            // If username does exist, indicate an error
            setFieldError("username", "Username is already registered.");
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    enableReinitialize: true,
  });

  const { values, touched, errors, handleBlur, handleChange, isSubmitting } =
    formik;

  return (
    <>
      <Stack spacing={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography variant="h3">{registrationSteps[0].title}</Typography>
          {/*@ts-ignore*/}
          <Typography
            component={Link}
            href={"/login"}
            variant="body1"
            sx={{ textDecoration: "none" }}
            color="primary"
          >
            Already have an account?
          </Typography>
        </Stack>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent={"space-around"}
        >
          <Grid item>
            <Button
              href={googleSessionLink}
              disabled={googleSessionLinkIsLoading}
              sx={{ p: 0, m: 0 }}
            >
              {googleSessionLinkIsLoading ? (
                <img
                  src={GoogleLightDisabled}
                  alt="Google Light Disabled"
                  height={41}
                />
              ) : (
                <img
                  src={GoogleLightNormal}
                  alt="Google Light Normal"
                  height={41}
                />
              )}
            </Button>
          </Grid>
          <Grid item>
            <Button
              href={microsoftSessionLink}
              disabled={microsoftSessionLinkIsLoading}
              sx={{ p: 0, m: 0 }}
            >
              <img src={SignInWithMicrosoft} alt="Sign in with Microsoft" />
            </Button>
          </Grid>
        </Grid>
        <Divider>
          <Typography>OR</Typography>
        </Divider>
        <form onSubmit={formik.handleSubmit} id="validation-forms">
          <Grid container>
            <Grid container item spacing={3}>
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="first-name-signup">
                    First Name*
                  </InputLabel>
                  <OutlinedInput
                    id="first-name-login"
                    type="firstname"
                    value={values.first_name}
                    name="first_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth
                    error={Boolean(touched.first_name && errors.first_name)}
                  />
                  {touched.first_name && errors.first_name && (
                    <FormHelperText error id="helper-text-first-name-signup">
                      {errors.first_name}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="last-name-signup">Last Name*</InputLabel>
                  <OutlinedInput
                    fullWidth
                    error={Boolean(touched.last_name && errors.last_name)}
                    id="last-name-signup"
                    type="lastname"
                    value={values.last_name}
                    name="last_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputProps={{}}
                  />
                  {touched.last_name && errors.last_name && (
                    <FormHelperText error id="helper-text-last-name-signup">
                      {errors.last_name}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="company-signup">Company</InputLabel>
                  <OutlinedInput
                    fullWidth
                    error={Boolean(touched.company && errors.company)}
                    id="company-signup"
                    value={values.company}
                    name="company"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputProps={{}}
                  />
                  {touched.company && errors.company && (
                    <FormHelperText error id="helper-text-company-signup">
                      {errors.company}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="email-signup">Email Address*</InputLabel>
                  <OutlinedInput
                    fullWidth
                    error={Boolean(touched.username && errors.username)}
                    id="email-login"
                    type="email"
                    value={values.username}
                    name="username"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputProps={{}}
                  />
                  {touched.username && errors.username && (
                    <FormHelperText error id="helper-text-username-signup">
                      {errors.username}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  By Signing up, you agree to our &nbsp;
                  <Typography
                    variant="subtitle2"
                    color="secondary"
                    component={Link}
                    href="https://creonesource.com/terms-of-use"
                    target="_blank"
                    underline="hover"
                  >
                    Terms of Service
                  </Typography>
                  &nbsp; and &nbsp;
                  <Typography
                    variant="subtitle2"
                    color="secondary"
                    component={Link}
                    href="https://creonesource.com/privacy-policy"
                    target="_blank"
                    underline="hover"
                  >
                    Privacy Policy
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <LoadingButton
                  disableElevation
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                  loading={isSubmitting}
                >
                  Continue
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Stack>
    </>
  );
};
