import { PlusOutlined } from "@ant-design/icons";
import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { FieldArray, useFormikContext } from "formik";
import React, { useState } from "react";

import { TextFieldCurrency } from "components/form/TextFieldCurrency";
import { Invoice } from "types/api/deal/invoice";
import { dealService } from "utils/axios";

interface DownloadInvoiceButtonProps {
  invoiceId: string;
  invoiceName?: string;
}

const DownloadInvoiceButton: React.FC<DownloadInvoiceButtonProps> = ({
  invoiceId,
  invoiceName,
}) => {
  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    try {
      setLoading(true);
      // Use the dealService Axios instance to call the endpoint
      const response = await dealService.get(
        `invoice/tables/${invoiceId}/pdf`,
        {
          responseType: "blob", // Important to receive binary data
        }
      );

      // Convert response to Blob
      const blob = new Blob([response.data], { type: "application/pdf" });

      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create a hidden <a> element to download the file
      const link = document.createElement("a");
      link.href = url;
      link.download = `${invoiceName}.pdf`;
      // (You can name the file however you wish.)
      document.body.appendChild(link);

      // Trigger download
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the invoice:", error);
      // You might display an error message or toast here
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleDownload}
      disabled={loading}
    >
      {loading ? "Downloading..." : "Download Invoice"}
    </Button>
  );
};

const InvoicesFieldArray: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<{
    invoices: Invoice[];
  }>();

  return (
    <FieldArray
      name="invoices"
      render={({ remove, push }) => {
        return (
          <>
            <TableContainer>
              <Table sx={{ minWidth: 800 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Invoice Date</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>No. of Payments</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Invoice Number</TableCell>
                    <TableCell align={"center"}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {values.invoices.map((detail, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <TextField
                          fullWidth
                          type="date"
                          value={detail.date || ""}
                          onChange={(e) =>
                            setFieldValue(
                              `invoices[${index}].date`,
                              e.target.value
                            )
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <TextFieldCurrency
                          fieldName={`invoices.${index}.amount`}
                          showLabel={false}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          type="number"
                          value={detail.payment_quantity || ""}
                          onChange={(e) =>
                            setFieldValue(
                              `invoices[${index}].payment_quantity`,
                              parseInt(e.target.value, 10)
                            )
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          value={detail.description || ""}
                          onChange={(e) =>
                            setFieldValue(
                              `invoices[${index}].description`,
                              e.target.value
                            )
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          value={detail.invoice_code || ""}
                          onChange={(e) =>
                            setFieldValue(
                              `invoices[${index}].invoice_code`,
                              e.target.value
                            )
                          }
                        />
                      </TableCell>

                      {/* Action */}
                      <TableCell align="center">
                        {!!detail?.id && (
                          <DownloadInvoiceButton
                            invoiceId={detail.id}
                            invoiceName={detail.invoice_code}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                {/* Footer for totals */}
              </Table>
            </TableContainer>
            <Grid container justifyContent="space-between">
              <Grid item xs={12} md={8}>
                <Box sx={{ pt: 2.5, pr: 2.5, pb: 2.5, pl: 0 }}>
                  <Button
                    color="primary"
                    startIcon={<PlusOutlined />}
                    onClick={() =>
                      push({
                        date: "",
                        payment_quantity: 1,
                        amount: 0,
                        invoice_code: "",
                        description: "",
                      })
                    }
                    variant="dashed"
                    sx={{ bgcolor: "transparent !important" }}
                  >
                    Add Item
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </>
        );
      }}
    />
  );
};

export default InvoicesFieldArray;
