import { Box, Button, FormControl, Stack, useTheme } from "@mui/material";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import { ReactNode, useState } from "react";

import { FilterSelectButton } from "components/filter/FilterBar/FilterComponents/FilterSelectButton";
import {
  parseDateRangeAsDayjsV2,
  parseDateRangeAsStrV2,
} from "pages/deal/utils/deal";
import {
  getConditionalFilterSx,
  isFilterEmpty,
} from "pages/deal/utils/dealFilters";

const { RangePicker } = DatePicker;
const YEAR_RANGE = [-2, -1, 0, 1];

const DateRangeFilterYears = (props: { setDateRange: any; setOpen: any }) => {
  const { setDateRange, setOpen } = props;

  return (
    <Stack
      direction="row"
      justifyContent="center"
      spacing={2}
      sx={{ py: 1.25 }}
    >
      {YEAR_RANGE.map((x, i) => (
        <Button
          key={i}
          variant="outlined"
          color="secondary"
          size="small"
          onClick={() => {
            setDateRange([
              dayjs().add(x, "year").startOf("year"),
              dayjs().add(x, "year").endOf("year"),
            ]);
            setOpen(false);
          }}
        >
          {dayjs().add(x, "year").get("year")}
        </Button>
      ))}
    </Stack>
  );
};

export function FilterDateRange(props: {
  label: string | ReactNode;
  value: any;
  onChange: any;
}) {
  const { label, value, onChange } = props;
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const id = `${_.kebabCase(label as string)}-filter`;

  const renderLabel = (x: any) => {
    if (typeof label === "object") {
      return label;
    }
    if (isFilterEmpty(x)) {
      return label;
    } else if (_.isArray(x) && x.length === 2) {
      const [start, end] = x;
      // Look for a match in the provided year ranges
      for (let i = 0; i < YEAR_RANGE.length; i++) {
        if (
          dayjs().add(YEAR_RANGE[i], "year").startOf("year").isSame(start) &&
          dayjs().add(YEAR_RANGE[i], "year").endOf("year").isSame(end)
        ) {
          return `${label}: ${dayjs().add(YEAR_RANGE[i], "year").year()}`;
        }
      }
      // Not a year, but is a valid date range
      return `${label}: ${start.format("M/D/YY")} - ${end.format("M/D/YY")}`;
    }
    // No match for year found or only one date entered
    return `${label}: Custom`;
  };

  const conditionalSx = getConditionalFilterSx(value, theme) ?? {};

  return (
    <FormControl sx={{ minWidth: 120, maxWidth: 300 }}>
      <FilterSelectButton
        id={id}
        open={open}
        setOpen={setOpen}
        conditionalSx={conditionalSx}
        label={renderLabel(parseDateRangeAsDayjsV2(value))}
      >
        <Box>
          <RangePicker
            open={open}
            onOpenChange={setOpen}
            size="large"
            value={parseDateRangeAsDayjsV2(value) as any}
            onChange={(x) => {
              if (x) {
                onChange(parseDateRangeAsStrV2(x as dayjs.Dayjs[]));
              } else {
                onChange(null);
              }
            }}
            renderExtraFooter={() => (
              <DateRangeFilterYears
                setDateRange={(x: any) => {
                  onChange(parseDateRangeAsStrV2(x));
                }}
                setOpen={setOpen}
              />
            )}
            format="M/D/YY"
          />
        </Box>
      </FilterSelectButton>
    </FormControl>
  );
}
