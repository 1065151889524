import { Box, Button, Collapse, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import MainCard from "components/MainCard";
import { useDealOrgPreferences } from "contexts/DealOrgPreferencesContext";
import { useEffectUpdateOnly } from "hooks/useEffectUpdateOnly";
import { parseFiltersAsParamsObject } from "pages/deal/utils/deal";
import {
  isFilterEmpty,
  removeEmptyFilterFromValue,
} from "pages/deal/utils/dealFilters";
import { SimpleOption } from "types/api/deal/form";
import {
  FilterBarLayout,
  FilterBarLayoutItem,
  FilterBarSectionLayout,
  FilterMetadata,
} from "types/filter";
import { BaseFilter } from "types/navigation/common";

const styles = {
  box: {
    mr: 1.5,
    mb: 1.5,
    float: "left",
    height: "42px",
  },
};

interface FilterBarProps<T extends BaseFilter> {
  applyFilters: () => void;
  disabledFilters: string[];
  hiddenFilters: string[];
  initialFilters: T;
  pendingFilters: Partial<T>;
  publishedFilters: T;
  resetFilters: () => void;
  setPendingFilters: (pendingFilters: Partial<T>) => void;
  setPublishedFilters: (publishedFilters: T) => void;
  filterBarLayout: FilterBarLayout;
  syncToQueryParams?: boolean;
}

export interface FilterComponentProps {
  disabled?: boolean;
  label: string;
  multiple?: boolean;
  onChange: (x: any) => void;
  optionFieldName?: string;
  options?: SimpleOption[];
  url?: string;
  value: any;
  setFilterValue?: (key: string, x: any, isLiveUpdate: boolean) => void;
  values?: BaseFilter;
}

function generateFilterComponent<T extends BaseFilter>(
  filterData: FilterMetadata,
  currentFilters: T,
  handleFilterChange: (key: string, x: any, isLiveUpdate: boolean) => void,
  hiddenFilters: string[],
  disabledFilters: string[],
  align: string
) {
  const {
    component: FilterComponent,
    key,
    label = "",
    multiple = false, // providing a default value if you want
    optionFieldName,
    options,
    isLiveUpdate = false, // providing a default value if you want
    url,
    visibility = () => true,
  } = filterData;
  const { data: dealOrgPreferences } = useDealOrgPreferences();
  const value = _.get(currentFilters, key);

  const handleChange = (x: any) => {
    handleFilterChange(key, x, isLiveUpdate);
  };
  const visible = useMemo(
    () => visibility(dealOrgPreferences),
    [dealOrgPreferences, visibility]
  );
  const hidden = _.includes(hiddenFilters, filterData.key);
  const disabled = _.includes(disabledFilters, filterData.key);

  return !visible || hidden ? null : (
    <Box sx={{ ...styles.box, float: align }} key={`${key}-list-item`}>
      <FilterComponent
        value={value}
        label={label}
        disabled={disabled}
        onChange={handleChange}
        options={options}
        optionFieldName={optionFieldName}
        multiple={multiple}
        setFilterValue={handleFilterChange}
        values={currentFilters}
        url={url}
      />
    </Box>
  );
}

export function FilterBar<T extends BaseFilter>({
  publishedFilters,
  pendingFilters,
  initialFilters,
  hiddenFilters,
  disabledFilters,
  setPendingFilters,
  setPublishedFilters,
  applyFilters,
  resetFilters,
  filterBarLayout,
  syncToQueryParams = true,
}: FilterBarProps<T>) {
  const [, setSearchParams] = useSearchParams();
  const theme = useTheme();
  // Trigger deal data update when published filters change
  useEffectUpdateOnly(() => {
    // Drop out early if not meant to sync
    if (!syncToQueryParams) return;

    // Only push params to the url bar if different from the default for the page
    if (!_.isEqual(publishedFilters, initialFilters)) {
      setSearchParams(parseFiltersAsParamsObject<T>(publishedFilters));
    } else {
      // Reset search params if current filters equal the page default initial filters
      setSearchParams({});
    }
  }, [publishedFilters]);

  // Get current combination of pending and applied filters
  const currentFilters = useMemo(
    () => ({ ...publishedFilters, ...pendingFilters }),
    [publishedFilters, pendingFilters]
  );
  const [expanded, setExpanded] = useState(false);

  const handleApplyFilters = () => {
    setExpanded(false);
    applyFilters();
  };

  const handleClearFilters = () => {
    setExpanded(false);
    resetFilters();
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const sortedAdditionalFilters = useMemo(
    () => _.sortBy(filterBarLayout.additionalFilters, "filter.label"),
    [filterBarLayout.additionalFilters]
  );

  const getFilterCount = (sectionLayout: FilterBarSectionLayout) => {
    /**
     * Returns the number of additional filter options that are currently applied.
     *
     * This function examines the `currentFilters` object to determine which filter
     * options are currently applied. It counts the number of options that are not empty
     * and returns the total count as a number.
     *
     * @returns The number of additional filter options currently applied.
     */
    return _.sumBy(sectionLayout, (x: FilterBarLayoutItem) => {
      const key = x.filter.key;
      const filterValue = _.get(currentFilters, key);
      return isFilterEmpty(filterValue) ? 0 : 1;
    });
  };

  const getCollapseButton = () => {
    /**
     * Returns a button component that toggles the visibility of additional filter options.
     *
     * This function creates a button that displays the text "Show More Filters" when the
     * `expanded` state is false, and "Show Less Filters" when the `expanded` state is true.
     * It also displays the number of additional filter options that are currently applied,
     * as returned by the `getFilterCount()` function. If there are no additional
     * filter options, the button is rendered with a gray color scheme.
     *
     * @returns A button component that toggles the visibility of the Collapse area.
     */

    const collapseText = expanded ? "Show Less Filters" : "Show More Filters";
    // Don't show button if no additional filters defined
    if (sortedAdditionalFilters.length === 0) {
      return null;
    }

    const showMoreFilterCount = getFilterCount(sortedAdditionalFilters);
    if (showMoreFilterCount > 0) {
      return (
        <Button
          variant="text"
          color="primary"
          size="small"
          onClick={handleExpandClick}
        >
          <b>
            {collapseText} ({showMoreFilterCount})
          </b>
        </Button>
      );
    } else {
      return (
        <Button
          variant="text"
          color="secondary"
          size="small"
          onClick={handleExpandClick}
        >
          {collapseText}
        </Button>
      );
    }
  };

  function handleFilterChange(key: string, x: any, isLiveUpdate: boolean) {
    const newValue = removeEmptyFilterFromValue(x);

    if (isLiveUpdate) {
      setPublishedFilters({ ...currentFilters, [key]: newValue });
    } else {
      setPendingFilters({ ...pendingFilters, [key]: newValue });
    }
  }

  return (
    <MainCard
      content={false}
      sx={{
        position: "sticky",
        top: "60px",
        bgcolor: theme.palette.background.paper,
        zIndex: 10,
      }}
    >
      <Box sx={{ p: 1, ml: 1.5, mt: 1.5 }}>
        <Box sx={{ ...styles.box, float: "right" }}>
          <Grid
            container
            columnSpacing={2.5}
            rowSpacing={0}
            alignItems="center"
            justifyContent={"flex-end"}
            wrap={"nowrap"}
            sx={{ height: "100%" }}
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleApplyFilters}
                disabled={_.isEmpty(pendingFilters)}
              >
                Apply Filters
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="text"
                color="secondary"
                size="small"
                onClick={handleClearFilters}
              >
                Reset Filters
              </Button>
            </Grid>
            <Grid item>{getCollapseButton()}</Grid>
          </Grid>
        </Box>
        {filterBarLayout.mainFilters.map((item: FilterBarLayoutItem) => {
          const { filter: filterData, align = "left" } = item;
          return generateFilterComponent(
            filterData,
            currentFilters,
            handleFilterChange,
            hiddenFilters,
            disabledFilters,
            align
          );
        })}

        <Collapse in={expanded} sx={{ width: "100%" }}>
          {sortedAdditionalFilters.map((item: FilterBarLayoutItem) => {
            const { filter: filterData, align = "left" } = item;
            return generateFilterComponent(
              filterData,
              currentFilters,
              handleFilterChange,
              hiddenFilters,
              disabledFilters,
              align
            );
          })}
        </Collapse>
      </Box>
    </MainCard>
  );
}
