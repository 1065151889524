import { FormInstance } from "contexts/FormDialogsContext";
import { dispatch } from "store";
import { openErrorNotification } from "store/reducers/common";
import { PropertyCreate } from "types/api/deal/property";
import { dealService } from "utils/axios";
import { handleRecordActionResponseV5 } from "utils/record";

export async function createPropertyAsync(
  property: PropertyCreate,
  pushForm: (form: FormInstance) => void
) {
  try {
    const response = await dealService.post(`/property/`, property);
    handleRecordActionResponseV5(response, pushForm);
    return response.data;
  } catch (error) {
    dispatch(openErrorNotification("Error creating property."));
    throw error;
  }
}

export async function updatePropertyAsync(
  id: number | string,
  property: Partial<PropertyCreate>,
  pushForm: (form: FormInstance) => void
) {
  try {
    const response = await dealService.put(`/property/${id}`, property);
    handleRecordActionResponseV5(response, pushForm);
    return response.data;
  } catch (error) {
    dispatch(openErrorNotification("Error updating property."));
    throw error;
  }
}

// export async function deletePropertyAsync(id: number | string) {
//   try {
//     const response = await dealService.delete(`/property/${id}`);
//     handleRecordActionResponseV4(response);
//   } catch (error) {
//     dispatch(openErrorNotification("Error deleting property."));
//     throw error;
//   }
// }
