import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { MouseEventHandler, useState } from "react";

import { DownloadChartIconButton } from "pages/deal/components/DownloadChartIconButton";
import { InfoTooltip } from "pages/deal/components/InfoTooltip";

export function ChartTitle(props: {
  title: string;
  tooltipText?: string | undefined;
  exportChartId?: string;
  downloadFunc: MouseEventHandler<HTMLButtonElement> | undefined;
  downloadEnabled: boolean;
  children?: any;
}) {
  const [exporting, setExporting] = useState(false);

  const handleExport = async () => {
    setExporting(true);
    await setTimeout(() => {}, 1000);
    _.invoke(props, "downloadFunc");
    await setTimeout(() => {}, 2000);
    setExporting(false);
  };

  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      sx={{ width: "100%", mb: 1 }}
    >
      <Typography variant={"h5"}>{props.title}</Typography>
      <Stack direction={"row"} justifyContent={"flex-end"}>
        {props.children}
        {props.tooltipText && <InfoTooltip title={props.tooltipText} />}
        <DownloadChartIconButton
          exportChartId={props.exportChartId ?? ""}
          onClick={handleExport}
          enabled={props.downloadEnabled}
        />
        {exporting && props.children}
      </Stack>
    </Stack>
  );
}
