import { FormInstance } from "contexts/FormDialogsContext";
import { dispatch } from "store";
import { openErrorNotification } from "store/reducers/common";
import { Report } from "types/api/deal/report/report";
import { dealService } from "utils/axios";
import { handleRecordActionResponseV5 } from "utils/record";

export async function createReportAsync(
  report: Report,
  pushForm: (form: FormInstance) => void
) {
  try {
    const response = await dealService.post(`/report/`, report);
    handleRecordActionResponseV5(response, pushForm);
    return response.data;
  } catch (error) {
    dispatch(openErrorNotification("Error creating report."));
    throw error;
  }
}

export async function updateReportAsync(
  id: number | string,
  report: Partial<Report>,
  pushForm: (form: FormInstance) => void
) {
  try {
    const response = await dealService.put(`/report/${id}`, report);
    handleRecordActionResponseV5(response, pushForm);
    return response.data;
  } catch (error) {
    dispatch(openErrorNotification("Error updating report."));
    throw error;
  }
}

// export async function deleteReportAsync(id: number | string) {
//   try {
//     const response = await dealService.delete(`/report/${id}`);
//     handleRecordActionResponseV4(response);
//   } catch (error) {
//     dispatch(openErrorNotification("Error deleting report."));
//     throw error;
//   }
// }
