import { useQuery } from "react-query";

import { ReportRead, ReportReadList } from "types/api/deal/report/report";
import { dealService } from "utils/axios";

export const getReportQueryKey = (report_id: string) => ["report", report_id];

export const useReportQuery = (report_id: string) => {
  const query = useQuery<ReportRead>({
    queryKey: getReportQueryKey(report_id),
    queryFn: () =>
      dealService.get(`/report/${report_id}`).then((response) => {
        return response.data;
      }),
  });

  return query;
};

export const getReportsQueryKey = (queryParams: URLSearchParams) => [
  "report_table",
  queryParams.toString(),
];

export const useReportsQuery = (queryParams: URLSearchParams) => {
  const query = useQuery<ReportReadList>({
    queryKey: getReportsQueryKey(queryParams),
    queryFn: () =>
      dealService
        .get(`/report/tables/overview`, { params: queryParams })
        .then((response) => {
          return response.data;
        }),
  });

  return query;
};
