import { useQuery } from "react-query";

import {
  CustomFormEntityEnum,
  FormFieldRead,
} from "types/api/deal/dynamic_form/form_field";
import { dealService } from "utils/axios";

export const getFormFieldQueryKey = (form_field_id: number) => [
  "form_field",
  form_field_id,
];

export const useFormFieldQuery = (form_field_id: number) => {
  const query = useQuery<FormFieldRead>({
    queryKey: getFormFieldQueryKey(form_field_id),
    queryFn: () =>
      dealService.get(`/form_field/${form_field_id}`).then((response) => {
        return response.data;
      }),
  });

  return query;
};

export const getFormFieldListQueryKey = (entity_type: CustomFormEntityEnum) => [
  "form_field_list",
  entity_type,
];

export const useFormFieldListQuery = (entity_type: CustomFormEntityEnum) => {
  const query = useQuery<FormFieldRead[]>({
    queryKey: getFormFieldListQueryKey(entity_type),
    queryFn: () =>
      dealService.get(`/form_field/type/${entity_type}`).then((response) => {
        return response.data;
      }),
  });

  return query;
};

export const getFormFieldDictQueryKey = (entity_type: CustomFormEntityEnum) => [
  "form_field_dict",
  entity_type,
];

export const useFormFieldDictQuery = (entity_type: CustomFormEntityEnum) => {
  const query = useQuery<Record<string, FormFieldRead>>({
    queryKey: getFormFieldDictQueryKey(entity_type),
    queryFn: () =>
      dealService
        .get(`/form_field/type_by_name/${entity_type}`)
        .then((response) => {
          return response.data;
        }),
  });

  return query;
};
