import dayjs from "dayjs";
import * as Yup from "yup";

import { userOptionLoadHandler } from "components/form/standard/utils/metadata";
import { TextFieldCurrency } from "components/form/TextFieldCurrency";
import { TextFieldWholeNumber } from "components/form/TextFieldWholeNumber";
import { useGetCurrentUserAsOption } from "hooks/useGetUserAsOption";
import { UserLookupField } from "pages/deal/components/LookupField";
import { SimpleOption } from "types/api/deal/form";
import { ObjectMetadata, StandardFormLayout } from "types/standardForm";

import { FragmentSection } from "../../../components/form/section/FragmentSection";

export const CommissionGoalMetadata: ObjectMetadata = {
  goal_amount: {
    fieldName: "goal_amount",
    displayName: "Commission",
    visibility: () => true,
    initialValue: undefined,
    _schema: Yup.number()
      .required("Commission is required")
      .min(0)
      .typeError(`Commission must be a number.`),
    component: TextFieldCurrency,
    submitHandler: (x) => x,
    loadHandler: (x) => x,
  },
  goal_deals: {
    fieldName: "goal_deals",
    displayName: "Deal Count",
    visibility: () => true,
    initialValue: undefined,
    _schema: Yup.number()
      .min(0)
      .nullable()
      .typeError(`Deal Count must be a number.`),
    component: TextFieldWholeNumber,
    submitHandler: (x) => x,
    loadHandler: (x) => x,
  },
  year: {
    fieldName: "year",
    displayName: "Year",
    visibility: () => true,
    initialValue: dayjs().year(),
    _schema: Yup.number().min(0).nullable().typeError(`Year must be a number.`),
    component: TextFieldWholeNumber,
    submitHandler: (x) => x,
    loadHandler: (x) => x,
  },
  user: {
    fieldName: "user",
    displayName: "User",
    visibility: () => true,
    initialValue: useGetCurrentUserAsOption,
    _schema: Yup.object()
      .required(`Contact Owner is required.`)
      .typeError("Contact Owner is required."),
    component: UserLookupField,
    submitHandler: (x: SimpleOption) => x.key,
    loadHandler: userOptionLoadHandler,
  },
};
