import { Container, DialogContent, DialogTitle, Grid } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import * as Sentry from "@sentry/react";
import _ from "lodash";
import React from "react";

import StandardField from "components/form/standard/StandardField";
import { StandardForm } from "components/form/standard/StandardForm";
import MainCard from "components/MainCard";
import { ContactMetadata } from "constants/objectMetadata/contactMetadata";
import { CloseButton } from "pages/deal/components/CloseButton";
import { startEditingRecord } from "store/reducers/record";
import {
  ContactRead,
  ContactUpdate,
  ContactWithFollowup,
} from "types/api/deal/contact";
import { FormIdentifier, StandardFormProps } from "types/record";
import { FormSubmitAction } from "types/standardForm";
import { createContactAsync, updateContactAsync } from "utils/contact";

export const ContactQuickCreateForm = ({
  record,
  incomingChanges,
  onCancel,
  successCallback,
  open,
  showAddAnother,
}: StandardFormProps<ContactRead & ContactWithFollowup>) => {
  const createFnAsync = async (
    contact: ContactWithFollowup,
    submitAction: FormSubmitAction = FormSubmitAction.save
  ) => {
    try {
      const record = await createContactAsync(contact);

      if (typeof successCallback === "function" && _.isObject(record))
        successCallback(record);

      if (submitAction === FormSubmitAction.saveAndAddAnother) {
        await new Promise((resolve) => setTimeout(resolve, 200));
        startEditingRecord(
          undefined,
          FormIdentifier.ContactQuickCreateForm,
          {},
          true
        );
      }
    } catch (error) {
      Sentry.captureException("Unable to create Contact.");
    }
  };

  const updateFnAsync = async (
    id: number | string,
    contact: Partial<ContactUpdate>
  ) => {
    try {
      const record = await updateContactAsync(id, contact);
      if (typeof successCallback === "function" && _.isObject(record))
        successCallback(record);
    } catch (error) {
      Sentry.captureException("Unable to update Contact.");
    }
  };

  return (
    <StandardForm<ContactWithFollowup>
      metadata={ContactMetadata}
      record={record}
      updateFn={updateFnAsync}
      createFn={createFnAsync}
      displayName={"Contact"}
      onCancel={onCancel}
      incomingChanges={incomingChanges}
      showAddAnother={showAddAnother}
      fullWidth={false}
      open={open}
    >
      {({ StandardFormActionsInstance }) => (
        <>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <DialogTitle>{!!record ? "Edit" : "Add"} Contact</DialogTitle>
            </Grid>
            <Grid item xs={true}></Grid>
            <Grid item>
              <StandardField field={ContactMetadata.owner} showLabel={false} />
            </Grid>
            <Grid item sx={{ mr: 1.5 }}>
              <CloseButton onClose={onCancel} />
            </Grid>
          </Grid>
          <DialogContent>
            <Container>
              <Grid container spacing={3}>
                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={12}>
                    <MainCard>
                      <Grid container columnSpacing={2} rowSpacing={1}>
                        <Grid item xs={12} sm={3}>
                          <StandardField field={ContactMetadata.first_name} />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <StandardField field={ContactMetadata.last_name} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <StandardField field={ContactMetadata.company} />
                        </Grid>
                        <Grid item xs={9} sm={4}>
                          <StandardField field={ContactMetadata.telephone1} />
                        </Grid>
                        <Grid item xs={3} sm={2}>
                          <StandardField
                            field={ContactMetadata.telephone1_ext}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <StandardField field={ContactMetadata.contact_type} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <StandardField field={ContactMetadata.telephone2} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <StandardField field={ContactMetadata.title} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <StandardField
                            field={ContactMetadata.email_address}
                          />
                        </Grid>
                      </Grid>
                    </MainCard>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </DialogContent>
          <DialogActions>{StandardFormActionsInstance}</DialogActions>
        </>
      )}
    </StandardForm>
  );
};
