import * as Yup from "yup";

import { TextFieldString } from "components/form/TextFieldString";
import { PropertyLookupField } from "pages/deal/components/LookupField";
import {
  parsePropertyAsSimpleOption,
  simpleOptionSubmitHandler,
} from "pages/deal/utils/deal_form";
import { VoucherObjectMetadataV3 } from "types/standardFormV3";

export const VoucherBaseMetadata: VoucherObjectMetadataV3 = {
  name: {
    fieldName: "name",
    displayName: "Voucher Name",
    _schema: Yup.string().required("Voucher Name is required."),
    component: TextFieldString,
    initialValue: "",
  },
  transaction_details: {
    fieldName: "transaction_details",
    displayName: "Transaction Details",
    _schema: Yup.array(),
    component: TextFieldString,
    initialValue: [],
  },
  invoices: {
    fieldName: "invoices",
    displayName: "Invoices",
    _schema: Yup.array(),
    component: TextFieldString,
    initialValue: [],
  },
  property_record: {
    fieldName: "property_record",
    displayName: "Property",
    initialValue: null,
    _schema: Yup.object().nullable(),
    component: PropertyLookupField,
    submitHandler: simpleOptionSubmitHandler,
    loadHandler: parsePropertyAsSimpleOption,
    submitFieldName: "property_id",
  },
};
