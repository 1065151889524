import { AxiosResponse } from "axios";
import _ from "lodash";

import { RecordMetadata } from "constants/record";
import { FormInstance } from "contexts/FormDialogsContext";
import { startEditingRecord } from "store/reducers/record";
import { EntityAction, RecordActionResponse } from "types/api/deal/api";
import { FormIdentifier, RecordIdentifier } from "types/record";
import { openNotification } from "utils/notistack";

export function handleRecordActionResponseV2(
  entityDisplayName: string,
  actionDisplayName: "created" | "updated" | "deleted",
  formIdentifier: FormIdentifier | null,
  response: AxiosResponse<any>
) {
  if (_.isObject(response.data.data)) {
    if (actionDisplayName === "deleted") {
      openNotification(`${entityDisplayName} ${actionDisplayName}.`);
    } else if (!!formIdentifier) {
      openNotification(
        `${entityDisplayName} ${actionDisplayName}.`,
        "success",
        `View ${entityDisplayName}`,
        () =>
          startEditingRecord(_.get(response.data, "data.id"), formIdentifier)
      );
    } else {
      openNotification(`${entityDisplayName} ${actionDisplayName}.`, "success");
    }
  }
}

export function handleRecordActionResponseV3(
  entityDisplayName: string,
  actionDisplayName: "created" | "updated" | "deleted",
  openRecord: ((id: number | undefined) => void) | null,
  response: AxiosResponse<any>
) {
  if (_.isObject(response.data.data)) {
    if (actionDisplayName === "deleted") {
      openNotification(`${entityDisplayName} ${actionDisplayName}.`);
    } else if (typeof openRecord === "function") {
      openNotification(
        `${entityDisplayName} ${actionDisplayName}.`,
        "success",
        `View ${entityDisplayName}`,
        () => openRecord(_.get(response.data, "data.id"))
      );
    } else {
      openNotification(`${entityDisplayName} ${actionDisplayName}.`, "success");
    }
  }
}

export function handleRecordActionResponseV4(
  response: AxiosResponse<RecordActionResponse[]>
) {
  if (_.isArray(response.data)) {
    response.data.forEach(({ id, type, action }) => {
      const recordMetadata = _.get(
        RecordMetadata,
        type as unknown as RecordIdentifier
      );
      if (action === EntityAction.delete) {
        openNotification(`${recordMetadata?.displayName} deleted.`);
      } else if (action === EntityAction.create) {
        openNotification(
          `${recordMetadata?.displayName} created.`,
          "success",
          `View ${recordMetadata?.displayName}`,
          () =>
            startEditingRecord(id, recordMetadata?.mainForm as FormIdentifier)
        );
      } else if (action === EntityAction.edit) {
        openNotification(
          `${recordMetadata?.displayName} updated.`,
          "success",
          `View ${recordMetadata?.displayName}`,
          () =>
            startEditingRecord(id, recordMetadata?.mainForm as FormIdentifier)
        );
      }
    });
  }
}

export function handleRecordActionResponseV5(
  response: AxiosResponse<RecordActionResponse[]>,
  pushForm: (form: FormInstance) => void
) {
  if (_.isArray(response.data)) {
    response.data.forEach(({ id, type, action }) => {
      const recordMetadata = _.get(
        RecordMetadata,
        type as unknown as RecordIdentifier
      );

      if (action === EntityAction.delete) {
        openNotification(`${recordMetadata?.displayName} deleted.`);
      } else if (action === EntityAction.create) {
        openNotification(
          `${recordMetadata?.displayName} created.`,
          "success",
          `View ${recordMetadata?.displayName}`,
          () =>
            pushForm({
              id: `${id}`,
              identifier:
                recordMetadata?.mainForm as FormInstance["identifier"],
            })
        );
      } else if (action === EntityAction.edit) {
        openNotification(
          `${recordMetadata?.displayName} updated.`,
          "success",
          `View ${recordMetadata?.displayName}`,
          () =>
            pushForm({
              id: `${id}`,
              identifier:
                recordMetadata?.mainForm as FormInstance["identifier"],
            })
        );
      }
    });
  }
}
