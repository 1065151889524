import { Grid } from "@mui/material";

import { ReadOnlyTextFieldString } from "./ReadOnlyTextFieldString";

export const ReadOnlyLotSize = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <ReadOnlyTextFieldString label={"Lot Size"} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ReadOnlyTextFieldString label={" "} placeholder={"Sq Ft."} />
      </Grid>
    </Grid>
  );
};
