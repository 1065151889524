import { useQuery } from "react-query";

import { PropertyExtended, PropertyReadList } from "types/api/deal/property";
import { dealService } from "utils/axios";

export const getPropertyRecordQueryKey = (property_id: string) => [
  "property_record",
  property_id,
];

export const usePropertyRecordQuery = (property_id: string) => {
  const query = useQuery<PropertyExtended>({
    queryKey: getPropertyRecordQueryKey(property_id),
    queryFn: () =>
      dealService.get(`/property/${property_id}`).then((response) => {
        return response.data;
      }),
  });

  return query;
};

export const getPropertyRecordsQueryKey = (queryParams: URLSearchParams) => [
  "property_table",
  queryParams.toString(),
];

export const usePropertyRecordsQuery = (queryParams: URLSearchParams) => {
  const query = useQuery<PropertyReadList>({
    queryKey: getPropertyRecordsQueryKey(queryParams),
    queryFn: () =>
      dealService
        .get(`/property/tables/overview`, { params: queryParams })
        .then((response) => {
          return response.data;
        }),
  });

  return query;
};
