import { PlusOutlined } from "@ant-design/icons";
import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import _ from "lodash";
import { Fragment, useMemo } from "react";
import {
  ColumnInstance,
  Row,
  useExpanded,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

import ScrollX from "components/ScrollX";
import {
  HeaderSort,
  HidingSelect,
  TablePagination,
} from "components/third-party/ReactTable";
import { GlobalFilter } from "utils/react-table";

interface AggregateTableProps<T extends object> {
  data: T[];
  columns: any[];
  defaultPageSize?: number;
  showAggregate?: boolean;
  skipPageReset: boolean;
  initialSortBy: {
    id: string;
    desc: boolean;
  }[];
  tableSize?: "small" | "medium" | undefined;
  initialHiddenColumns: string[];
  startEditing: (x: Row<T> | null) => void;
  showHeader?: boolean;
  dataLabel?: string;
}

const AggregateTable = <T extends object>({
  columns,
  data,
  dataLabel,
  defaultPageSize = 10,
  initialHiddenColumns,
  initialSortBy,
  startEditing,
  showAggregate = false,
  showHeader = false,
  skipPageReset,
  tableSize,
}: AggregateTableProps<T>) => {
  const theme = useTheme();

  const matchDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const defaultColumn = useMemo(() => ({}), []);

  const initialState = useMemo(
    () => ({
      pageIndex: 0,
      pageSize: defaultPageSize,
      hiddenColumns: initialHiddenColumns,
      sortBy: initialSortBy,
    }),
    [defaultPageSize, initialSortBy]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    allColumns,
    rows,
    // @ts-ignore
    page,
    // @ts-ignore
    gotoPage,
    // @ts-ignore
    setPageSize,
    // @ts-ignore
    state: { globalFilter, pageIndex, pageSize, hiddenColumns },
    // @ts-ignore
    preGlobalFilteredRows,
    // @ts-ignore
    setGlobalFilter,
  } = useTable<T>(
    {
      columns,
      data: _.isArray(data) ? data : [],
      defaultColumn,
      initialState,
      // @ts-ignore
      autoResetPage: !skipPageReset,
      autoResetExpanded: !skipPageReset,
      autoResetGroupBy: !skipPageReset,
      autoResetSelectedRows: !skipPageReset,
      autoResetSortBy: !skipPageReset,
      autoResetFilters: !skipPageReset,
      autoResetRowState: !skipPageReset,
      autoResetHiddenColumns: !skipPageReset,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );

  return (
    <ScrollX>
      <Stack spacing={3}>
        {showHeader && (
          <Stack
            direction={matchDownSM ? "column" : "row"}
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
            sx={{ p: 3, pb: 0 }}
          >
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              size="small"
            />
            <Stack
              direction={matchDownSM ? "column" : "row"}
              alignItems="center"
              spacing={1}
            >
              {(dataLabel === "Contact" || dataLabel === "Prospect") && (
                <HidingSelect
                  hiddenColumns={hiddenColumns!}
                  setHiddenColumns={() => {}}
                  allColumns={allColumns}
                />
              )}
              <Button
                variant="contained"
                startIcon={<PlusOutlined />}
                onClick={() => startEditing(null)}
              >
                Add {!!dataLabel ? dataLabel : "Record"}
              </Button>
            </Stack>
          </Stack>
        )}
        <Box sx={{ width: "100%", overflowX: "auto", display: "block" }}>
          <Table {...getTableProps()} size={tableSize}>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => {
                    return (
                      <TableCell
                        {...column.getHeaderProps([
                          { className: column.className },
                        ])}
                      >
                        <HeaderSort column={column} sort />
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {page.map((row: { isSelected: boolean } & Row<T>) => {
                prepareRow(row);

                return (
                  <Fragment>
                    <TableRow
                      {...row.getRowProps()}
                      sx={{
                        bgcolor: row.isSelected
                          ? alpha(theme.palette.primary.lighter, 0.35)
                          : "inherit",
                      }}
                    >
                      {row.cells.map((cell) => {
                        // @ts-ignore
                        const column: {
                          onClick: any;
                          className: any;
                        } & ColumnInstance = cell.column;
                        return (
                          <TableCell
                            onClick={
                              column.onClick ?? (() => startEditing(row))
                            }
                            sx={{
                              cursor: "pointer",
                              backgroundColor: theme.palette.common.white,
                            }}
                            {...cell.getCellProps([
                              { className: column.className },
                            ])}
                          >
                            {cell.render("Cell")}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </Fragment>
                );
              })}
              {!showAggregate && (
                <TableRow>
                  <TableCell sx={{ p: 2 }} colSpan={10}>
                    <TablePagination
                      gotoPage={gotoPage}
                      rowCount={rows.length}
                      setPageSize={setPageSize}
                      pageIndex={pageIndex}
                      pageSize={pageSize}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            {showAggregate && (
              <TableFooter sx={{ borderBottomWidth: 2 }}>
                {footerGroups.map((group) => (
                  <TableRow {...group.getFooterGroupProps()}>
                    {group.headers.map((column: any) => {
                      return (
                        <TableCell
                          {...column.getFooterProps([
                            { className: column.className },
                          ])}
                        >
                          {column.render("Footer")}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableFooter>
            )}
          </Table>
        </Box>
        {showAggregate && (
          <Box sx={{ px: 2 }}>
            <TablePagination
              gotoPage={gotoPage}
              rowCount={rows.length}
              setPageSize={setPageSize}
              pageIndex={pageIndex}
              pageSize={pageSize}
            />
          </Box>
        )}
      </Stack>
    </ScrollX>
  );
};

export default AggregateTable;
