import * as React from "react";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";

import { FilterComponentProps } from "components/filter/FilterBar/FilterBar";
import { FilterAutocomplete } from "components/filter/FilterBar/FilterComponents/Autocomplete/FilterAutocomplete";
import { getBaseQueryOptionsDealService } from "pages/deal/utils/api";
import { SimpleOption } from "types/api/deal/form";

export interface FilterAutocompleteLookupProps extends FilterComponentProps {
  url?: string;
  label: string;
  value: (string | number | null)[];
  onChange: (value: (string | number | null)[]) => void;
  sx?: object;
}

export function FilterAutocompleteLookup(props: FilterAutocompleteLookupProps) {
  const { url = "", label, value, onChange, sx } = props;

  const [hasFetchedData, setHasFetchedData] = useState(false); // State to track if data has been fetched

  const queryOptions = useMemo(
    () => getBaseQueryOptionsDealService<SimpleOption[]>(url),
    [url]
  );
  const {
    data: options = [],
    refetch,
    isLoading,
  } = useQuery<SimpleOption[]>({
    ...queryOptions,
    enabled: false, // Initially disable automatic fetching
  });

  // Updated onOpen to check if data has already been fetched
  const handleOnOpen = () => {
    if (!hasFetchedData) {
      refetch().then(() => setHasFetchedData(true));
    }
  };

  return (
    <FilterAutocomplete
      label={label}
      value={value}
      onChange={onChange}
      options={options}
      sx={sx}
      onOpen={handleOnOpen}
      isLoading={isLoading}
    />
  );
}
