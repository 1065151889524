import { Box, Checkbox } from "@mui/material";
import { useField } from "formik";
import React from "react";

import { CreoneField } from "components/form/basic/creone_field";

export function CheckboxField({
  fieldName,
  displayName,
}: {
  fieldName: string;
  displayName?: string;
}) {
  const [field] = useField(fieldName);

  return (
    <CreoneField fieldName={fieldName} displayName={displayName}>
      <Box sx={{ mt: -0.5 }}>
        <Checkbox
          className="size-medium"
          {...field}
          checked={field.value}
          onChange={(e) => field.onChange(e)}
        />
      </Box>
    </CreoneField>
  );
}
