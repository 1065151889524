import { useMemo } from "react";

import { SelectOption } from "components/form/SelectOption";
import { getUSStateOptions } from "pages/deal/constants/address";

export const SelectUSState = ({
  fieldName,
  displayName,
}: {
  fieldName: string;
  displayName: string;
}) => {
  const stateOptions = useMemo(() => getUSStateOptions(), []);

  return (
    <SelectOption
      fieldName={fieldName}
      displayName={displayName}
      options={stateOptions}
    />
  );
};
