import { useQuery } from "react-query";

import { VoucherRead, VoucherReadList } from "types/api/deal/voucher";
import { dealService } from "utils/axios";

export const getVoucherQueryKey = (voucher_id: string) => [
  "voucher",
  voucher_id,
];

export const useVoucherQuery = (voucher_id: string) => {
  const query = useQuery<VoucherRead>({
    queryKey: getVoucherQueryKey(voucher_id),
    queryFn: () =>
      dealService.get(`/voucher/${voucher_id}`).then((response) => {
        return response.data;
      }),
  });

  return query;
};

export const getVouchersQueryKey = (queryParams: URLSearchParams) => [
  "voucher_table",
  queryParams.toString(),
];

export const useVouchersQuery = (queryParams: URLSearchParams) => {
  const query = useQuery<VoucherReadList>({
    queryKey: getVouchersQueryKey(queryParams),
    queryFn: () =>
      dealService
        .get(`/voucher/tables/overview`, { params: queryParams })
        .then((response) => {
          return response.data;
        }),
  });

  return query;
};
