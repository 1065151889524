import * as React from "react";

import { FilterComponentProps } from "components/filter/FilterBar/FilterBar";
import { FilterAutocompleteLookup } from "components/filter/FilterBar/FilterComponents/Autocomplete/FilterAutocompleteLookup";

interface FilterAutocompleteDealOptionProps extends FilterComponentProps {
  optionFieldName?: string;
  label: string;
  value: (string | number | null)[];
  onChange: (value: (string | number | null)[]) => void;
  sx?: object;
}

export function FilterAutocompleteDealOption(
  props: FilterAutocompleteDealOptionProps
) {
  const { optionFieldName = "" } = props;

  const url = `/form/lookup/option/${optionFieldName}`;

  return <FilterAutocompleteLookup {...props} url={url} />;
}
