import { BaseTableV2 } from "types/api/deal/schemaAddOns";
import { Voucher } from "types/api/deal/voucher"; // Assuming enums are also exported from schema_add_ons

export enum DetailTypeEnum {
  RENT = "rent",
  FREE_RENT = "free_rent",
  EXPENSE = "expense",
  SALE = "sale",
  FEE = "fee",
  OTHER = "other",
}

export interface TransactionDetailBase extends BaseTableV2 {
  voucher_id: string;

  detail_type: DetailTypeEnum;

  quantity: number;
  total_amount: number;

  commission_percent: number;

  date_start?: string;
  date_end?: string;
}

export interface TransactionDetail extends TransactionDetailBase {
  voucher: Voucher;
}

export interface TransactionDetailRead extends TransactionDetailBase {
  total_per_quantity: number;
  commission_amount: number;
}

export interface TransactionDetailSimpleRead extends TransactionDetailBase {}
