import { InfoCircleFilled } from "@ant-design/icons";
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as Sentry from "@sentry/react";
import { Form, Formik, FormikConfig } from "formik";
import { isNil, size } from "lodash";
import React, { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import * as Yup from "yup";

import { usePopForm } from "contexts/FormDialogsContext";
import { pluralizeWord } from "pages/deal/utils/deal";
import { formatWholeNumber } from "pages/deal/utils/reporting";
import { dispatch } from "store";
import { openErrorNotification } from "store/reducers/common";
import { ContactDeleteBulk, EditType } from "types/api/deal/contact_bulk";
import { contactBulkDeleteAsync } from "utils/record_access";

interface DeleteContactMeta {
  is_filter: boolean;
  record_ids: number[];
  queryParams: URLSearchParams;
  count: number;
}

function isValidMeta(meta: any): meta is DeleteContactMeta {
  return (
    !isNil(meta) &&
    typeof meta.is_filter === "boolean" &&
    Array.isArray(meta.record_ids) &&
    meta.queryParams instanceof URLSearchParams &&
    typeof meta.count === "number"
  );
}

interface DeleteContactBulkFormBaseProps {
  meta: DeleteContactMeta;
  submitData: (data: any) => Promise<void>;
}

function DeleteContactBulkFormBase({
  submitData,
  meta,
}: DeleteContactBulkFormBaseProps) {
  const [step, setStep] = useState<"form" | "confirmation">("form");
  const popForm = usePopForm();

  const realOnSubmit: FormikConfig<any>["onSubmit"] = async (
    _values,
    { setSubmitting }
  ) => {
    try {
      await submitData(null);
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  const onSubmit: FormikConfig<any>["onSubmit"] = useCallback(
    (values, formikHelpers) => {
      if (step === "form") {
        setStep("confirmation");
        // Wait 1s to unblock the final save button
        setTimeout(() => formikHelpers.setSubmitting(false), 1000);
      } else {
        realOnSubmit(values, formikHelpers);
      }
    },
    [step, realOnSubmit]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Formik
        initialValues={{}}
        validationSchema={Yup.object()}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={true}
      >
        {(formik) => (
          <Form>
            <Dialog open={true} scroll={"paper"} maxWidth={"xs"}>
              <DialogTitle>Delete Contacts</DialogTitle>
              <DialogContent dividers>
                {step === "form" ? (
                  <Stack sx={{ height: 1 }} justifyContent={"center"}>
                    <br />
                    <Typography>
                      You are about to delete{" "}
                      <b>
                        {formatWholeNumber(meta.count)}{" "}
                        {pluralizeWord(meta.count, "contact", "contacts")}.
                      </b>{" "}
                      Click Next to continue.
                    </Typography>
                    <br />
                  </Stack>
                ) : (
                  // Confirmation screen
                  <>
                    <br />
                    <Typography>
                      Please confirm the changes you are about to make.
                    </Typography>
                    <br />
                    <Typography>
                      <b>
                        {formatWholeNumber(meta.count)}{" "}
                        {pluralizeWord(meta.count, "contact", "contacts")}
                      </b>{" "}
                      will be deleted.
                    </Typography>
                    <br />
                  </>
                )}
              </DialogContent>
              <DialogActions>
                {step === "form" ? (
                  <Grid
                    container
                    style={{ display: "flex" }}
                    alignItems="center"
                  >
                    <Grid item xs sx={{ flexGrow: 1 }}></Grid>
                    <Grid item>
                      {formik.submitCount > 0 && size(formik.errors) ? (
                        <Alert color="error" icon={<InfoCircleFilled />}>
                          Please correct one or more errors on the form.
                        </Alert>
                      ) : null}
                    </Grid>
                    <Grid item xs>
                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        spacing={2}
                        alignItems="center"
                        sx={{ mr: 1 }}
                      >
                        <Button
                          color="secondary"
                          onClick={popForm}
                          size={"large"}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          onClick={() => formik.handleSubmit()}
                          size={"large"}
                          variant="contained"
                          disabled={formik.isSubmitting}
                        >
                          Next
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                ) : (
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    spacing={2}
                    alignItems="center"
                    sx={{ mr: 1 }}
                  >
                    <Button color="secondary" onClick={popForm} size={"large"}>
                      Cancel
                    </Button>
                    <Button onClick={() => setStep("form")} size={"large"}>
                      Back
                    </Button>
                    <Button
                      type="submit"
                      onClick={() => formik.handleSubmit()}
                      size={"large"}
                      variant="contained"
                      disabled={formik.isSubmitting}
                      color={"error"}
                    >
                      Delete
                    </Button>
                  </Stack>
                )}
              </DialogActions>
            </Dialog>
          </Form>
        )}
      </Formik>
    </LocalizationProvider>
  );
}

export interface DeleteContactBulkFormProps {
  id?: string;
  changes?: object;
  handleClose: () => void;
  successCallback: any;
  meta?: any;
}

export const DeleteContactBulkForm = ({
  handleClose,
  successCallback,
  meta,
}: DeleteContactBulkFormProps) => {
  const queryClient = useQueryClient();

  if (!isValidMeta(meta)) return null;

  const submitData = useCallback(async () => {
    try {
      const updateObj: ContactDeleteBulk = {
        is_filter: meta.is_filter,
        record_ids: meta.record_ids,
      };
      await contactBulkDeleteAsync(updateObj, meta.queryParams);
      await queryClient.invalidateQueries();
      if (typeof successCallback === "function") successCallback();
      handleClose();
    } catch (error) {
      dispatch(openErrorNotification("Error deleting records."));
      Sentry.captureException("Unable to bulk delete contact records.");
    }
  }, [meta, handleClose, queryClient, successCallback]);

  return <DeleteContactBulkFormBase submitData={submitData} meta={meta} />;
};
