import { openNotification } from "utils/notistack";

export function openSuccessNotification(message: string) {
  openNotification(message, "success");

  return () => {};
}

export function openErrorNotification(message: string) {
  openNotification(message, "error");

  return () => {};
}

export function openInfoNotification(message: string) {
  openNotification(message, "info");

  return () => {};
}
