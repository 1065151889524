import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Form, Formik, useField } from "formik";
import { orderBy } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";

import { FilterBar } from "components/filter/FilterBar/FilterBar";
import { DealFilterBarLayout } from "components/filter/FilterBar/layouts/dealFilterMetadata";
import { SelectOption } from "components/form/SelectOption";
import { FormDialogActions } from "components/form/standard/FormDialogActions";
import {
  deriveYupSchemaFromMetadataV3,
  getInitialValuesV3,
  getOnSubmitV3,
} from "components/form/standard/utils/metadataV3";
import { TextFieldString } from "components/form/TextFieldString";
import InsightsTableTemp from "components/table/InsightsTableTemp";
import { DealMetadata } from "constants/objectMetadata/dealMetadata";
import { ReportMetadata } from "constants/objectMetadata/reportMetadata";
import { usePushForm } from "contexts/FormDialogsContext";
import { useUsersDisplay } from "contexts/UserDisplayContext";
import { YupRequiredFieldsProvider } from "contexts/YupRequiredFieldsContext";
import useAuth from "hooks/useAuth";
import { SimpleOptionLookupMultiple } from "pages/deal/components/LookupField";
import { baseDealFilters } from "pages/deal/constants/dealFilters";
import { parseFiltersAsParamsObject } from "pages/deal/utils/deal";
import { useAggregateReportQuery } from "queries/useAggregateReport";
import { getReportQueryKey, useReportQuery } from "queries/useReport";
import { startViewingDeals } from "store/reducers/dealV2";
import { EntityEnum } from "types/api/deal/enum";
import { Report, ReportRead } from "types/api/deal/report/report";
import { DealStatus } from "types/deal";
import { SortOrderEnum } from "types/navigation/common";
import { DealFilter } from "types/navigation/dealNavigation";
import { createReportAsync, updateReportAsync } from "utils/report";

const dealOptionColumns = [
  {
    key: "asset_type_id",
    label: DealMetadata.asset_type.displayName,
  },
  {
    key: "client_industry_id",
    label: DealMetadata.client_industry.displayName,
  },
  {
    key: "counterparty_broker_company_id",
    label: DealMetadata.counterparty_broker_company.displayName,
  },
  {
    key: "lost_reason_id",
    label: DealMetadata.lost_reason.displayName,
  },
  {
    key: "property_name_id",
    label: DealMetadata.property_name.displayName,
  },
  {
    key: "shared_broker_company_id",
    label: DealMetadata.shared_broker_company.displayName,
  },
  {
    key: "source_type_id",
    label: DealMetadata.source_type.displayName,
  },
  {
    key: "submarket_id",
    label: DealMetadata.submarket.displayName,
  },
  {
    key: "tenant_name_id",
    label: DealMetadata.tenant_name.displayName,
  },
  {
    key: "contact_id",
    label: DealMetadata.contact.displayName,
  },
  {
    key: "outside_broker_id",
    label: DealMetadata.outside_broker.displayName,
  },
  {
    key: "shared_broker_id",
    label: DealMetadata.shared_broker.displayName,
  },
  {
    key: "deal_type",
    label: DealMetadata.deal_type.displayName,
  },
  {
    key: "shared_deal",
    label: "Shared Deal",
  },
  {
    key: "hire_to_close_category",
    label: "Hire Date to Close Date",
  },
  {
    key: "broker",
    label: "Broker",
  },
];

const sortedDealOptionColumns = orderBy(dealOptionColumns, "label");
interface ReportFormikValues {
  id?: string;
  name: string;
}

interface ReportFormBaseProps {
  record?: ReportRead;
  changes?: Partial<ReportFormikValues>;
  submitData: (data: any) => Promise<void>;
}

function ReportPreviewFormElement() {
  const [reportLogicField] = useField("report_logic");
  const [reportFilterField] = useField("report_filter");

  const queryParams = useMemo(
    () => parseFiltersAsParamsObject(reportFilterField.value),
    [reportFilterField.value]
  );

  const { data } = useAggregateReportQuery(
    EntityEnum.deal,
    reportLogicField.value,
    queryParams
  );

  return data ? (
    <InsightsTableTemp
      columns={[]}
      initialSortBy={[]}
      onClick={(row) =>
        startViewingDeals(row?.original.category_filters as DealFilter)
      }
      id={"insights_table_temp"}
      data={data}
    />
  ) : null;
}

const ReportFiltersFormElement = () => {
  const [field, , helpers] = useField("report_filter");

  const initialFilters: DealFilter = {
    ...baseDealFilters,
    sort: ["name"],
    sort_order: [SortOrderEnum.asc],
  };

  const [pendingFilters, setPendingFilters] = useState<Partial<DealFilter>>({});

  return (
    <FilterBar<DealFilter>
      publishedFilters={field.value}
      pendingFilters={pendingFilters}
      initialFilters={initialFilters}
      hiddenFilters={[]}
      disabledFilters={[]}
      setPendingFilters={(pending) => setPendingFilters(pending)}
      setPublishedFilters={(published) => {
        helpers.setValue(published);
        setPendingFilters({});
      }}
      applyFilters={() => {
        helpers.setValue({
          ...field.value,
          ...pendingFilters,
        });
        setPendingFilters({});
      }}
      resetFilters={() => {
        helpers.setValue(initialFilters);
        setPendingFilters({});
      }}
      filterBarLayout={DealFilterBarLayout}
      syncToQueryParams={false}
    />
  );
};
function ReportFormBase({
  record,
  changes = {},
  submitData,
}: ReportFormBaseProps) {
  const { user } = useAuth();
  const usersDisplay = useUsersDisplay();

  const initialValues = useMemo(
    () =>
      getInitialValuesV3<ReportRead, Partial<ReportFormikValues>>(
        ReportMetadata,
        record,
        changes,
        usersDisplay,
        user
      ),
    [changes, record, user, usersDisplay]
  );

  const validationSchema = useMemo(
    () => deriveYupSchemaFromMetadataV3(ReportMetadata),
    []
  );

  const onSubmit = useMemo(
    () => getOnSubmitV3(ReportMetadata, submitData),
    [submitData]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Formik
        initialValues={{
          ...initialValues,
          temp_field: [
            { key: "1", label: "Count" },
            { key: "2", label: "Commission" },
            { key: "3", label: "Transaction Value" },
          ],
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={true}
      >
        <YupRequiredFieldsProvider validationSchema={validationSchema}>
          <Form>
            <Dialog open={true} scroll={"paper"} maxWidth={"lg"}>
              <DialogTitle>{record ? "Edit" : "New"} Report</DialogTitle>
              <DialogContent dividers>
                <Stack spacing={4}>
                  <TextFieldString
                    fieldName={"name"}
                    displayName={"Report Name"}
                  />
                  <Divider />
                  <Typography fontWeight={600}>Report Logic</Typography>
                  <Grid container alignItems={"center"} columnSpacing={2}>
                    <Grid item>
                      <Typography>Group</Typography>
                    </Grid>
                    <Grid item>
                      <SelectOption
                        fieldName={"entity_type"}
                        showLabel={false}
                        options={[{ key: "deal", label: "Deals" }]}
                      />
                    </Grid>
                    <Grid item>
                      <Typography>by</Typography>
                    </Grid>
                    <Grid item>
                      <SelectOption
                        fieldName={"report_logic.group_by_fields.0"}
                        showLabel={false}
                        options={sortedDealOptionColumns}
                      />
                    </Grid>
                    <Grid item>
                      <Typography>and view</Typography>
                    </Grid>
                    <Grid item>
                      <SelectOption
                        fieldName={"report_logic.aggregate_fields.0.operation"}
                        showLabel={false}
                        options={[{ key: "sum", label: "Sum" }]}
                      />
                    </Grid>
                    <Grid item>
                      <Typography>of</Typography>
                    </Grid>
                    <Grid item>
                      <SimpleOptionLookupMultiple
                        fieldName={"temp_field"}
                        showLabel={false}
                        options={[
                          { key: "1", label: "Count" },
                          { key: "2", label: "Commission" },
                          { key: "3", label: "Transaction Value" },
                        ]}
                      />
                    </Grid>
                  </Grid>
                  <Divider />
                  <Typography fontWeight={600}>Filters</Typography>
                  <ReportFiltersFormElement />
                  <Divider />
                  <Typography fontWeight={600}>Preview</Typography>
                  <Box>
                    <ReportPreviewFormElement />
                  </Box>
                </Stack>
              </DialogContent>
              <FormDialogActions />
            </Dialog>
          </Form>
        </YupRequiredFieldsProvider>
      </Formik>
    </LocalizationProvider>
  );
}

interface ReportEditFormProps {
  id: string;
  changes?: Partial<ReportFormikValues>;
  handleClose: () => void;
}

const ReportEditForm = ({ id, changes, handleClose }: ReportEditFormProps) => {
  const pushForm = usePushForm();

  const queryClient = useQueryClient();

  const submitData = useCallback(
    async (record: Report) => {
      await updateReportAsync(id, record, pushForm);
      await queryClient.invalidateQueries(getReportQueryKey(id));
      await queryClient.invalidateQueries(["report_table"]);
      handleClose();
    },
    [handleClose, id, pushForm, queryClient]
  );

  const { data } = useReportQuery(id);

  return !!data ? (
    <ReportFormBase submitData={submitData} changes={changes} record={data} />
  ) : null;
};

interface ReportFormProps {
  changes?: Partial<ReportFormikValues>;
  handleClose: () => void;
}

const ReportForm = ({ changes, handleClose }: ReportFormProps) => {
  const pushForm = usePushForm();

  const queryClient = useQueryClient();

  const submitData = useCallback(
    async (record: Report) => {
      await createReportAsync(record, pushForm);
      await queryClient.invalidateQueries(["report_table"]);
      handleClose();
    },
    [handleClose, pushForm, queryClient]
  );

  return <ReportFormBase submitData={submitData} changes={changes} />;
};

const ReportFormPage = ({
  id,
  handleClose,
}: {
  id?: string;
  handleClose: () => void;
}) => {
  return id ? (
    <ReportEditForm id={id} handleClose={handleClose} />
  ) : (
    <ReportForm handleClose={handleClose} />
  );
};

export default ReportFormPage;
