import { InputAdornment } from "@mui/material";
import { useField } from "formik";
import _ from "lodash";
import NumberFormat from "react-number-format";

import { CreoneField } from "components/form/basic/creone_field";
import { PercentTextField } from "components/form/NumberFormatTextField";

export function PercentField({
  fieldName,
  displayName,
}: {
  fieldName: string;
  displayName?: string;
}) {
  const [field, , helpers] = useField(fieldName);
  const handleBlur = () => {
    helpers.setTouched(true);
  };

  return (
    <CreoneField fieldName={fieldName} displayName={displayName}>
      <NumberFormat
        size={"medium"}
        fullWidth
        id={fieldName}
        thousandSeparator={false}
        fixedDecimalScale={false}
        decimalScale={2}
        placeholder="0"
        value={_.isNumber(field.value) ? field.value * 100 : field.value}
        onValueChange={(val, sourceInfo) => {
          if (sourceInfo.source === "event") {
            const floatVal = _.isNumber(val.floatValue)
              ? val.floatValue / 100
              : val.floatValue;
            helpers.setValue(floatVal);
          }
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
        onBlur={handleBlur}
        customInput={PercentTextField}
      />
    </CreoneField>
  );
}
