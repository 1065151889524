import { useNavigate, useParams } from "react-router";
import { useLocation } from "react-router-dom";

import { RecordEditModal } from "modals/RecordEditModal";
import { FormIdentifier, RecordDetails } from "types/record";

export const DealViewFormPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const incomingChanges = location.state as object;

  const recordDetails: RecordDetails = {
    id: params.id ?? undefined,
    formIdentifier: FormIdentifier.DealViewProForm,
    incomingChanges: incomingChanges,
    isQuickCreate: false,
    callbackFunction: () => {},
  };

  return (
    <RecordEditModal
      key={"test"}
      record={recordDetails}
      handleClose={() => {
        navigate(-1);
      }}
      isDialog={false}
    />
  );
};
