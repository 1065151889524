import { Breakpoint, Dialog } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Form, Formik } from "formik";
import { FormikHelpers } from "formik/dist/types";
import { ReactNode, useMemo, useState } from "react";

import ConditionalWrapper from "components/form/standard/ConditionalWrapper";
import { StandardFormActions } from "components/form/standard/StandardFormActions";
import {
  deriveYupSchemaFromMetadata,
  getSubmitHandler,
  useInitialValues,
} from "components/form/standard/utils/metadata";
import { useDealOrgPreferences } from "contexts/DealOrgPreferencesContext";
import { YupDescriptionProvider } from "contexts/YupDescriptionContext";
import { FormSubmitAction, ObjectMetadata } from "types/standardForm";

interface StandardFormProps<T extends Record<string, any>> {
  metadata: ObjectMetadata;
  record: T | null;
  children: (props: {
    StandardFormActionsInstance: ReactNode;
    setValues: FormikHelpers<any>["setValues"];
  }) => ReactNode;
  createFn?: (record: T, submitAction?: FormSubmitAction) => Promise<void>;
  updateFn: (
    id: number,
    record: Partial<T>,
    submitAction?: FormSubmitAction
  ) => Promise<void>;
  deleteFn?: (id: number, submitAction?: FormSubmitAction) => Promise<void>;
  incomingChanges?: Partial<T>;
  displayName: string;
  onCancel: () => void;
  showAddAnother?: boolean;
  fullWidth?: boolean;
  open: boolean;
  saveText?: string;
  maxWidth?: false | Breakpoint;
  isNewEditableSections?: boolean;
  isDialog?: boolean;
  handlePrint?: (summary: boolean) => void;
}

export function StandardForm<T extends Record<string, any>>({
  metadata,
  record,
  children,
  createFn = async () => {},
  updateFn,
  deleteFn,
  incomingChanges,
  displayName,
  onCancel,
  showAddAnother = false,
  fullWidth = false,
  maxWidth,
  open,
  saveText = "Save",
  isNewEditableSections = false,
  isDialog = true,
  handlePrint,
}: StandardFormProps<T>) {
  // Infer form properties from prop configuration
  const isCreating = !record;
  const canDelete = typeof deleteFn === "function";
  const [submitAction, setSubmitAction] = useState<FormSubmitAction>(
    FormSubmitAction.save
  );

  const { data: dealOrgPreferences } = useDealOrgPreferences();

  // Derive initial values based on presets, record values, and incoming changes
  const initialValues = useInitialValues(
    metadata,
    record,
    incomingChanges ?? {}
  );
  // Compile validation schema from metadata definitions
  const validationSchema = useMemo(
    () => deriveYupSchemaFromMetadata(metadata, dealOrgPreferences),
    [metadata, dealOrgPreferences]
  );
  // Define submit handler
  const submitHandler = useMemo(
    () => getSubmitHandler(metadata, record, submitAction, updateFn, createFn),
    [createFn, metadata, record, submitAction, updateFn]
  );

  const deleteHandler = useMemo(
    () => async () => {
      if (canDelete) await deleteFn(record?.id);
    },
    [canDelete, deleteFn, record?.id]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Formik
        initialValues={{ ...initialValues, _submit_action: "" }}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
        enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={true}
      >
        {({ values, isSubmitting, handleSubmit, resetForm, setValues }) => {
          const onCancelWithFormReset = (_event?: object, reason?: string) => {
            if (reason && reason === "backdropClick") return;
            onCancel();
            setTimeout(() => resetForm(), 300);
          };
          return (
            <YupDescriptionProvider
              objectSchema={validationSchema}
              values={values}
            >
              <Form>
                <ConditionalWrapper
                  condition={isDialog}
                  wrapper={(x) => (
                    <Dialog
                      onClose={onCancelWithFormReset}
                      open={open}
                      scroll={"paper"}
                      fullWidth={fullWidth}
                      maxWidth={maxWidth}
                    >
                      {x}
                    </Dialog>
                  )}
                >
                  {children({
                    StandardFormActionsInstance: (
                      <StandardFormActions
                        creating={isCreating}
                        canDelete={canDelete}
                        displayName={displayName}
                        handleClose={onCancelWithFormReset}
                        saveDisabled={isSubmitting}
                        handleDelete={deleteHandler}
                        handleSubmit={
                          handleSubmit as (
                            e?: React.FormEvent<HTMLFormElement> | undefined
                          ) => Promise<void>
                        }
                        showAddAnother={showAddAnother}
                        setSubmitAction={setSubmitAction}
                        saveText={saveText}
                        isNewEditableSections={isNewEditableSections}
                        handlePrint={handlePrint}
                      />
                    ),
                    setValues,
                  })}
                </ConditionalWrapper>
              </Form>
            </YupDescriptionProvider>
          );
        }}
      </Formik>
    </LocalizationProvider>
  );
}
