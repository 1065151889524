import { createSlice } from "@reduxjs/toolkit";
import { isEqual } from "lodash";

import { BASE_PROPERTY_FILTERS } from "constants/filters/propertyFilters";
import { parseParamsAsFilters } from "pages/deal/utils/deal";
import { dispatch } from "store";
import { SortOrderEnum } from "types/navigation/common";
import {
  PropertyFilter,
  PropertyNavigation,
} from "types/navigation/propertyNavigation";
import { RecordIdentifier } from "types/record";
import { captureUIEvent } from "utils/eventCapture";

const initialState: {
  initial: PropertyFilter;
  published: PropertyFilter;
  pending: Partial<PropertyFilter>;
  navigation: PropertyNavigation;
} = {
  initial: {
    ...BASE_PROPERTY_FILTERS,
    sort: ["name"],
    sort_order: [SortOrderEnum.asc],
  },
  published: {
    ...BASE_PROPERTY_FILTERS,
    sort: ["name"],
    sort_order: [SortOrderEnum.asc],
  },
  pending: {},
  navigation: {
    hiddenFilters: [],
    disabledFilters: [],
  },
};

const propertyNavigation = createSlice({
  name: "propertyNavigation",
  initialState,
  reducers: {
    setFilters(state, action) {
      state.pending = action.payload;
    },
    setInitialFilters(state, action) {
      state.initial = action.payload;
      state.pending = {};
    },
    setPublishedFilters(state, action) {
      state.published = action.payload;
      state.pending = {};
    },
    setInitialAndPublishedFilters(state, action) {
      state.initial = action.payload;
      state.published = action.payload;
      state.pending = {};
    },
    applyFilters(state) {
      captureUIEvent("filters_applied", {
        recordType: RecordIdentifier.Property,
        filters: { ...state.published, ...state.pending },
      });

      state.published = { ...state.published, ...state.pending };
      state.pending = {};
    },
    resetFilters(state) {
      captureUIEvent("filters_reset", {
        recordType: RecordIdentifier.Property,
      });

      state.published = state.initial;
      state.pending = {};
    },
    setNavigation(state, action) {
      state.navigation = action.payload;
    },
    setSort(state, action) {
      const { sort, sort_order } = action.payload;

      if (
        !isEqual(state.published.sort, sort) ||
        !isEqual(state.published.sort_order, sort_order)
      ) {
        state.published = { ...state.published, ...action.payload };
      }
    },
  },
});

export default propertyNavigation.reducer;

export const setInitialPropertyFilters = (filters: PropertyFilter) =>
  dispatch(propertyNavigation.actions.setInitialFilters(filters));
export const setPublishedPropertyFilters = (filters: PropertyFilter) =>
  dispatch(propertyNavigation.actions.setPublishedFilters(filters));
export const setInitialAndPublishedPropertyFilters = (
  filters: PropertyFilter
) =>
  dispatch(propertyNavigation.actions.setInitialAndPublishedFilters(filters));
export const setPropertyFilters = (filters: Partial<PropertyFilter>) =>
  dispatch(propertyNavigation.actions.setFilters(filters));
export const applyPropertyFilters = () =>
  dispatch(propertyNavigation.actions.applyFilters());
export const resetPropertyFilters = () =>
  dispatch(propertyNavigation.actions.resetFilters());

export const setPropertyNavigation = (navigation: PropertyNavigation) =>
  dispatch(propertyNavigation.actions.setNavigation(navigation));
export const setPropertySort = (sort: string[], sort_order: string[]) =>
  dispatch(propertyNavigation.actions.setSort({ sort, sort_order }));

export function setInitialPropertyFilterState(
  initialFilters: PropertyFilter,
  searchParams: URLSearchParams
) {
  const { queryFilters, queryFiltersExist } = parseParamsAsFilters(
    BASE_PROPERTY_FILTERS,
    searchParams
  );
  if (queryFiltersExist) {
    setInitialPropertyFilters(initialFilters);
    setPublishedPropertyFilters({ ...initialFilters, ...queryFilters });
  } else {
    setInitialAndPublishedPropertyFilters(initialFilters);
  }
}
