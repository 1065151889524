import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { IconButton } from "@mui/material";
import { MouseEventHandler } from "react";

import { DEAL_TOOLTIP } from "../constants/tooltip";

import { LightTooltip } from "./InfoTooltip";

export function DownloadChartIconButton(props: {
  exportChartId: string;
  onClick: MouseEventHandler<HTMLButtonElement> | undefined;
  enabled: boolean;
}) {
  const { exportChartId, onClick, enabled } = props;

  return (
    <LightTooltip title={DEAL_TOOLTIP.downloadAsPng}>
      <IconButton
        size="small"
        id={`${exportChartId}-button`}
        onClick={onClick}
        disabled={!enabled}
        sx={{ display: enabled ? "inline" : "none" }}
      >
        <FileDownloadOutlinedIcon fontSize="small" color="primary" />
      </IconButton>
    </LightTooltip>
  );
}
