import {
  Avatar,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  forwardRef,
  useEffect,
  ForwardRefExoticComponent,
  RefAttributes,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { activeItem } from "store/reducers/menu";
import { LinkTarget, NavItemType } from "types/menu";
import { RootStateProps } from "types/root";

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

interface Props {
  item: NavItemType;
  level: number;
}

const NavItem = ({ item, level }: Props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const menu = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen, openItem } = menu;

  let itemTarget: LinkTarget = "_self";
  if (item.target) {
    itemTarget = "_blank";
  }

  let listItemProps: {
    component:
      | ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>>
      | string;
    href?: string;
    target?: LinkTarget;
  } = {
    component: forwardRef((props) => (
      <Link {...props} to={item.url!} target={itemTarget} />
    )),
  };
  if (item?.external) {
    listItemProps = { component: "a", href: item.url, target: itemTarget };
  }

  const Icon = item.icon!;
  const itemIcon = item.icon ? <Icon style={{ fontSize: "1.25rem" }} /> : false;

  const isSelected = openItem.findIndex((id) => id === item.id) > -1;

  // active menu item on page load
  useEffect(() => {
    if (pathname && pathname.includes("product-details")) {
      if (item.url && item.url.includes("product-details")) {
        dispatch(activeItem({ openItem: [item.id] }));
      }
    }

    if ((item.url && pathname.startsWith(item.url)) || pathname === item.url) {
      dispatch(activeItem({ openItem: [item.id] }));
    }
    // eslint-disable-next-line
  }, [pathname]);

  const textColor = theme.palette.mode === "dark" ? "grey.400" : "text.primary";
  const iconSelectedColor =
    theme.palette.mode === "dark" && drawerOpen
      ? "text.primary"
      : "primary.main";

  return (
    <ListItemButton
      {...listItemProps}
      onClick={item.onClick}
      disabled={item.disabled}
      selected={isSelected}
      sx={{
        zIndex: 1201,
        pl: drawerOpen ? `${level * 20}px` : 1.5,
        py: 1.25,
        mx: drawerOpen ? 1.5 : 0,
        mb: drawerOpen ? 0.5 : 0,
        borderRadius: drawerOpen ? "8px" : 0,
        ...(drawerOpen && {
          "&:hover": {
            bgcolor:
              theme.palette.mode === "dark" ? "divider" : "primary.lighter",
          },
          "&.Mui-selected": {
            bgcolor:
              theme.palette.mode === "dark" ? "divider" : "primary.lighter",
            color: iconSelectedColor,
            "&:hover": {
              color: iconSelectedColor,
              bgcolor:
                theme.palette.mode === "dark" ? "divider" : "primary.lighter",
            },
          },
        }),
        ...(!drawerOpen && {
          "&:hover": {
            bgcolor: "transparent",
          },
          "&.Mui-selected": {
            "&:hover": {
              bgcolor: "transparent",
            },
            bgcolor: "transparent",
          },
        }),
      }}
    >
      {itemIcon && (
        <ListItemIcon
          sx={{
            minWidth: 28,
            color: isSelected ? iconSelectedColor : iconSelectedColor,
            ...(!drawerOpen && {
              borderRadius: 1.5,
              width: 36,
              height: 36,
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                bgcolor:
                  theme.palette.mode === "dark"
                    ? "secondary.light"
                    : "secondary.lighter",
              },
            }),
            ...(!drawerOpen &&
              isSelected && {
                bgcolor:
                  theme.palette.mode === "dark"
                    ? "primary.900"
                    : "primary.lighter",
                "&:hover": {
                  bgcolor:
                    theme.palette.mode === "dark"
                      ? "primary.darker"
                      : "primary.lighter",
                },
              }),
          }}
        >
          {itemIcon}
        </ListItemIcon>
      )}
      {(drawerOpen || (!drawerOpen && level !== 1)) && (
        <ListItemText
          primary={
            <Typography
              variant="h6"
              sx={{ color: isSelected ? iconSelectedColor : textColor }}
            >
              {item.title}
            </Typography>
          }
        />
      )}
      {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
          sx={{
            ...item.chip.style,
            ...(isSelected && { backgroundColor: item.chip.style?.color }),
            ...(isSelected && {
              color: item.chip.style?.backgroundColor,
            }),
          }}
        />
      )}
    </ListItemButton>
  );
};

export default NavItem;
