import _ from "lodash";
import { NavigateFunction, SetURLSearchParams } from "react-router-dom";

import { clearEditingRecordStack } from "store/reducers/record";
import { RecordIdentifier } from "types/record";

export const handlePreview = (
  id: number | string,
  type: RecordIdentifier,
  searchParams: URLSearchParams,
  setSearchParams: SetURLSearchParams
) => {
  searchParams.set("preview", id.toString());
  searchParams.set("type", type);
  setSearchParams(searchParams);
};

export const handleDealFormNavigate = (
  incomingChanges: object = {},
  navigate: NavigateFunction,
  id?: number
) => {
  clearEditingRecordStack();
  navigate(_.isNil(id) ? "/deals" : `/deals/${id}`, { state: incomingChanges });
};
