import { Box, Stack } from "@mui/material";
import { map } from "lodash";
import React, { useMemo } from "react";
import { Row } from "react-table";

import MainCard from "components/MainCard";
import AggregateTable from "components/table/AggregateTable";
import { ChartTitle } from "pages/deal/components/ChartTitle";
import { formatCurrency, formatWholeNumber } from "pages/deal/utils/reporting";
import {
  AggregateRowData,
  AggregateTableResponse,
} from "types/api/deal/report/report_api";

interface InsightsTableProps {
  columns: any[];
  initialSortBy: {
    id: string;
    desc: boolean;
  }[];
  onClick: (x: Row<AggregateRowData> | null) => void;
  id: string;
  data: AggregateTableResponse;
}

const InsightsTable = ({
  initialSortBy,
  onClick,
  data,
}: InsightsTableProps) => {
  const { columns: apiColumns, all_data } = data;

  const columns = useMemo(
    () =>
      map(apiColumns, (x) => ({
        Header: x.header,
        accessor: x.accessor,
        id: x.id,
        ...(x.cell_format && {
          Cell: ({ value }: { value: number | undefined; row: any }) => {
            switch (x.cell_format) {
              case "whole_number":
                return <Box>{formatWholeNumber(value)}</Box>;
              case "currency":
                return <Box>{formatCurrency(value)}</Box>;
              default:
                return <Box>{value}</Box>; // Default behavior if no matching format
            }
          },
          className:
            x.cell_format === "currency" || x.cell_format === "whole_number"
              ? "cell-right" // Align numbers to the right
              : undefined,
        }),
      })),
    [apiColumns]
  );

  return (
    <Stack direction={"column"} sx={{ height: 1 }}>
      <ChartTitle
        title={""}
        downloadFunc={() => {}}
        downloadEnabled={false}
      ></ChartTitle>
      <MainCard content={false} sx={{ height: 1 }}>
        <Box>
          <AggregateTable<AggregateRowData>
            data={all_data}
            columns={columns}
            initialHiddenColumns={["deal_id"]}
            initialSortBy={initialSortBy}
            defaultPageSize={100}
            skipPageReset={true}
            startEditing={onClick}
          />
        </Box>
      </MainCard>
    </Stack>
  );
};

export default InsightsTable;
