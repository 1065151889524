import { BellOutlined } from "@ant-design/icons";
import {
  Alert,
  AlertTitle,
  Badge,
  Box,
  Button,
  ClickAwayListener,
  Popper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import IconButton from "components/@extended/IconButton";
import Transitions from "components/@extended/Transitions";
import MainCard from "components/MainCard";
import {
  getUserNotificationsQueryKey,
  useUserNotificationsQuery,
} from "queries/useUserNotifications";
import { dispatch } from "store";
import { openErrorNotification } from "store/reducers/common";
import {
  MessageType,
  UserNotification,
} from "types/api/user_management/user_notification";
import { axiosUserServices, dealService } from "utils/axios";
import { handleRecordActionResponseV4 } from "utils/record";

interface ConnectionAlertProps {
  title: string;
  id: number;
  handleClose: () => void;
}

const ConnectionAlert: React.FC<ConnectionAlertProps> = ({ title, id }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const notificationQueryKey = getUserNotificationsQueryKey();

  const disableNotification = async () => {
    await axiosUserServices.put(`/user/notifications/${id}`, {
      enabled: false,
    });
  };

  const onDismiss = async () => {
    try {
      await disableNotification();
      await queryClient.invalidateQueries(notificationQueryKey);
    } catch (error) {
      dispatch(openErrorNotification("Error dismissing notification."));
    }
  };

  const onAction = async () => {
    try {
      await disableNotification();
      await queryClient.invalidateQueries(notificationQueryKey);
      navigate("/settings/user/integrations");
    } catch (error) {
      dispatch(openErrorNotification("Error dismissing notification."));
    }
  };

  return (
    <Alert
      severity="error"
      sx={{
        p: 2,
        width: 1,
        "& .MuiAlert-message": { width: 1 },
      }}
    >
      <AlertTitle>{title}</AlertTitle>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        sx={{ mt: 1 }}
      >
        <Button onClick={onDismiss} color={"success"}>
          Dismiss
        </Button>
        <Button variant="outlined" onClick={onAction} color={"success"}>
          Fix it
        </Button>
      </Stack>
    </Alert>
  );
};

const HeaderNotification = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("md"));

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState<boolean>(false);

  // React Query to fetch notifications
  const {
    data: notifications,
    isLoading,
    isError,
    error,
  } = useUserNotificationsQuery();

  // Compute unread count from notifications
  const unreadCount = notifications?.length || 0;

  useEffect(() => {
    if (unreadCount == 0 && open) {
      setOpen(false);
    }
  }, [open, unreadCount]);

  // Drop out early if they have no messages
  if (!unreadCount) return null;

  const handleToggle = () => {
    setOpen((prevOpen: boolean) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const iconBackColorOpen = "grey.100";

  // Map the message_type to user-friendly messages
  const mapNotificationMessage = (
    notification: UserNotification
  ): ReactNode => {
    switch (notification.message_type) {
      case MessageType.OUTLOOK_EMAIL_INTEGRATION_ERROR:
        return (
          <ConnectionAlert
            title={"We lost connection to your Outlook Emails."}
            id={notification.id}
            handleClose={() => setOpen(false)}
          />
        );
      case MessageType.OUTLOOK_CONTACT_INTEGRATION_ERROR:
        return (
          <ConnectionAlert
            title={"We lost connection to your Outlook Contacts."}
            id={notification.id}
            handleClose={() => setOpen(false)}
          />
        );
      case MessageType.GOOGLE_EMAIL_INTEGRATION_ERROR:
        return (
          <ConnectionAlert
            title={"We lost connection to your Gmail account."}
            id={notification.id}
            handleClose={() => setOpen(false)}
          />
        );
      case MessageType.GOOGLE_CONTACT_INTEGRATION_ERROR:
        return (
          <ConnectionAlert
            title={"We lost connection to your Gmail Contacts."}
            id={notification.id}
            handleClose={() => setOpen(false)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        color="secondary"
        variant="light"
        sx={{
          color: "text.primary",
          bgcolor: open ? iconBackColorOpen : "transparent",
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? "profile-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge badgeContent={unreadCount} color="error">
          <BellOutlined />
        </Badge>
      </IconButton>
      <Popper
        placement={matchesXs ? "bottom" : "bottom-end"}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [matchesXs ? -5 : 0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions
            type="grow"
            position={matchesXs ? "top" : "top-right"}
            in={open}
            {...TransitionProps}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <Box>
                {/* Show loading message */}
                {isLoading && (
                  <MainCard elevation={0} border={false} content={false}>
                    <Box
                      sx={{
                        p: 2,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography variant="body2" color="textSecondary">
                        Loading notifications...
                      </Typography>
                    </Box>
                  </MainCard>
                )}

                {/* Show error message if error occurred */}
                {isError && (
                  <MainCard elevation={0} border={false} content={false}>
                    <Box
                      sx={{
                        p: 2,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography variant="body2" color="error">
                        Error: {(error as Error).message}
                      </Typography>
                    </Box>
                  </MainCard>
                )}

                {/* Show message if no notifications are available */}
                {!isLoading && notifications && notifications.length === 0 && (
                  <MainCard elevation={0} border={false} content={false}>
                    <Box
                      sx={{
                        p: 2,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <Typography variant="body1">
                        No new notifications
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        Check back later for updates
                      </Typography>
                    </Box>
                  </MainCard>
                )}

                {/* Render notification items if available */}
                <Stack spacing={1}>
                  {notifications &&
                    notifications.map((notification: any) => (
                      <Box key={notification.id} sx={{ m: 5 }}>
                        {mapNotificationMessage(notification)}
                      </Box>
                    ))}
                </Stack>
              </Box>
            </ClickAwayListener>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default HeaderNotification;
