import { PersonAdd } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import * as React from "react";

import { usePushForm } from "contexts/FormDialogsContext";
import { LightTooltip } from "pages/deal/components/InfoTooltip";

export default function ContactShareButton({
  contactId,
}: {
  contactId: number;
}) {
  const theme = useTheme();
  const pushForm = usePushForm();

  return (
    <LightTooltip title={`Share`}>
      <IconButton
        id="basic-button"
        onClick={() =>
          pushForm({ identifier: "ShareContactForm", id: `${contactId}` })
        }
        sx={{ color: theme.palette.common.black }}
      >
        <PersonAdd fontSize="small" />
      </IconButton>
    </LightTooltip>
  );
}
