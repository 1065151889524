import { filterEmpty } from "types/navigation/common";
import { ContactFilter } from "types/navigation/contactNavigation";

export const baseContactFilters: ContactFilter = {
  next_activity_type_v2: filterEmpty,
  city: filterEmpty,
  client_industry_id: filterEmpty,
  company_id: filterEmpty,
  company_name: filterEmpty,
  contact_type_id: filterEmpty,
  created_at: filterEmpty,
  created_by: filterEmpty,
  date_range: filterEmpty,
  is_prospect: filterEmpty,
  next_activity_date: filterEmpty,
  owner: filterEmpty,
  prospect_types: filterEmpty,
  size_est: filterEmpty,
  size_type: filterEmpty,
  state: filterEmpty,
  status: filterEmpty,
  asset_type_id: filterEmpty,
  submarket_id: filterEmpty,
  property_name_id: filterEmpty,
  group_id: filterEmpty,
  title: filterEmpty,
};
