import { dispatch } from "store";
import {
  openErrorNotification,
  openSuccessNotification,
} from "store/reducers/common";
import { User } from "types/api/user_management/user";
import { FormIdentifier } from "types/record";
import { axiosUserServices } from "utils/axios";
import { openNotification } from "utils/notistack";
import { handleRecordActionResponseV2 } from "utils/record";

export async function createUserAsync(user: User) {
  try {
    const response = await axiosUserServices.post(`/user/REPLACE`, user);
    handleRecordActionResponseV2(
      "User",
      "created",
      FormIdentifier.UserForm,
      response
    );
    return response.data.data;
  } catch (error) {
    dispatch(openErrorNotification("Error adding user."));
    throw error;
  }
}

export async function updateUserAsync(
  id: number | string,
  user: Partial<User>
) {
  try {
    const response = await axiosUserServices.put(
      `/user/team/member/v2/${id}`,
      user
    );
    handleRecordActionResponseV2(
      "User",
      "updated",
      FormIdentifier.UserForm,
      response
    );
    return response.data.data;
  } catch (error) {
    dispatch(openErrorNotification("Error updating user."));
    throw error;
  }
}

export async function resendActivationEmail(id: number | string) {
  try {
    await axiosUserServices.post(
      `/user/team/member/${id}/resend_activation_email/`
    );
    dispatch(openSuccessNotification(`Activation email has been sent.`));
  } catch (error) {
    dispatch(openErrorNotification(`Error sending activation email.`));
    throw error;
  }
}

export async function updateProfileAsync(user: Partial<User>) {
  try {
    const response = await axiosUserServices.put(`/user/profile`, user);
    openNotification(`Profile updated`);
    return response.data.data;
  } catch (error) {
    dispatch(openErrorNotification("Error updating user."));
    throw error;
  }
}
