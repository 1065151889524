import { PlusOutlined } from "@ant-design/icons";
import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import {
  ColumnInstance,
  Row,
  useExpanded,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

import ScrollX from "components/ScrollX";
import {
  HeaderSort,
  HidingSelect,
  TablePagination,
} from "components/third-party/ReactTable";
import { getBaseQueryOptionsDealService } from "pages/deal/utils/api";
import { parseFiltersAsParamsObject } from "pages/deal/utils/deal";
import { BaseFilter } from "types/navigation/common";
import {
  convertFilterSortByToReactTable,
  convertReactTableSortByToFilter,
} from "utils/navigation/common";
import { GlobalFilterV2 } from "utils/react-table";

interface AggregateTableProps<T extends object> {
  dataUrl: string;
  filterObj: BaseFilter;
  columns: any[];
  defaultPageSize?: number;
  showAggregate?: boolean;
  skipPageReset: boolean;
  tableSize?: "small" | "medium" | undefined;
  initialHiddenColumns: string[];
  startEditing: (x: Row<T> | null) => void;
  handleAdd?: () => void;
  showHeader?: boolean;
  dataLabel?: string;
}

const defaultContactOverviewList = {
  data: [],
  stats: { total_count: 0, page_count: 0 },
};

const AggregateTableV2 = <T extends object>({
  dataUrl,
  filterObj,
  columns,
  dataLabel,
  defaultPageSize = 10,
  initialHiddenColumns,
  startEditing,
  handleAdd,
  showAggregate = false,
  showHeader = false,
  skipPageReset,
  tableSize,
}: AggregateTableProps<T>) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const [globalFilter, setGlobalFilter] = useState(null);

  const [tableSpecificFilter, setTableSpecificFilter] = useState<
    Partial<BaseFilter>
  >({});

  // Source the data from the API
  const [overviewDataPersistent, setOverviewDataPersistent] = useState<any>(
    defaultContactOverviewList
  );
  const queryParams = useMemo(
    () => parseFiltersAsParamsObject({ ...filterObj, ...tableSpecificFilter }),
    [filterObj, tableSpecificFilter]
  );
  const queryOptions = useMemo(
    () => getBaseQueryOptionsDealService<any>(dataUrl, queryParams),
    [queryParams]
  );

  const { data: overviewData = defaultContactOverviewList, isLoading } =
    useQuery<any>(queryOptions);

  useEffect(() => {
    // Persist the Deals Overview Data in between loads. This is to counteract the "stale-while-revalidating" logic from react-query.
    if (!isLoading) {
      setOverviewDataPersistent(overviewData);
    }
  }, [overviewData]);

  const initialSortBy = useMemo(
    () =>
      convertFilterSortByToReactTable(
        filterObj.sort ?? [],
        filterObj.sort_order ?? []
      ),
    [filterObj.sort, filterObj.sort_order]
  );

  const defaultColumn = useMemo(() => ({}), []);

  const initialState = useMemo(
    () => ({
      pageIndex: 0,
      pageSize: defaultPageSize,
      hiddenColumns: initialHiddenColumns,
      sortBy: initialSortBy,
    }),
    [defaultPageSize, initialSortBy]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    allColumns,
    // @ts-ignore
    page,
    // @ts-ignore
    gotoPage,
    // @ts-ignore
    setPageSize,
    // @ts-ignore
    state: { pageIndex, pageSize, hiddenColumns, sortBy },
  } = useTable<T>(
    {
      columns,
      data: _.isArray(overviewDataPersistent?.data)
        ? overviewDataPersistent.data
        : [],
      defaultColumn,
      initialState,
      // @ts-ignore
      autoResetPage: !skipPageReset,
      autoResetExpanded: !skipPageReset,
      autoResetGroupBy: !skipPageReset,
      autoResetSelectedRows: !skipPageReset,
      autoResetSortBy: !skipPageReset,
      autoResetFilters: !skipPageReset,
      autoResetRowState: !skipPageReset,
      autoResetHiddenColumns: !skipPageReset,
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: overviewDataPersistent.stats.page_count,
      manualSortBy: true,
    },
    useSortBy,
    useExpanded,
    usePagination
  );

  useEffect(() => {
    setTableSpecificFilter((x) => ({
      ...x,
      skip: (pageIndex ?? 0) * (pageSize ?? 0),
      limit: pageSize,
    }));
  }, [pageIndex, pageSize]);

  useEffect(() => {
    const { sortIds, sortOrders } = convertReactTableSortByToFilter(sortBy);
    // Update table specific filter and reset skip when sort changes
    setTableSpecificFilter((x) => ({
      ...x,
      sort: sortIds,
      sort_order: sortOrders,
      skip: 0,
    }));
    gotoPage(0);
  }, [sortBy]);

  useEffect(() => {
    setTableSpecificFilter((x) => ({ ...x, search: globalFilter, skip: 0 }));
    gotoPage(0);
  }, [globalFilter]);

  useEffect(() => {
    // Update table specific filter and reset skip when published filters change
    setTableSpecificFilter((x) => ({ ...x, ...filterObj, skip: 0 }));
    gotoPage(0);
  }, [filterObj]);

  return (
    <ScrollX>
      <Stack spacing={3}>
        {showHeader && (
          <Stack
            direction={matchDownSM ? "column" : "row"}
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
            sx={{ p: 3, pb: 0 }}
          >
            <GlobalFilterV2
              totalCount={overviewDataPersistent.stats.total_count}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              size="small"
            />
            <Stack
              direction={matchDownSM ? "column" : "row"}
              alignItems="center"
              spacing={1}
            >
              {(dataLabel === "Contact" || dataLabel === "Prospect") && (
                <HidingSelect
                  hiddenColumns={hiddenColumns!}
                  setHiddenColumns={() => {}}
                  allColumns={allColumns}
                />
              )}
              <Button
                variant="contained"
                startIcon={<PlusOutlined />}
                onClick={
                  typeof handleAdd === "function"
                    ? () => handleAdd()
                    : () => startEditing(null)
                }
              >
                Add {!!dataLabel ? dataLabel : "Record"}
              </Button>
            </Stack>
          </Stack>
        )}
        <Box sx={{ width: "100%", overflowX: "auto", display: "block" }}>
          <Table {...getTableProps()} size={tableSize}>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => {
                    return (
                      <TableCell
                        {...column.getHeaderProps([
                          { className: column.className },
                        ])}
                      >
                        <HeaderSort column={column} sort />
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {page.map((row: { isSelected: boolean } & Row<T>) => {
                prepareRow(row);

                return (
                  <TableRow
                    {...row.getRowProps()}
                    sx={{
                      bgcolor: row.isSelected
                        ? alpha(theme.palette.primary.lighter, 0.35)
                        : "inherit",
                    }}
                  >
                    {row.cells.map((cell) => {
                      // @ts-ignore
                      const column: {
                        onClick: any;
                        className: any;
                      } & ColumnInstance = cell.column;
                      return (
                        <TableCell
                          onClick={column.onClick ?? (() => startEditing(row))}
                          sx={{
                            cursor: "pointer",
                            backgroundColor: theme.palette.common.white,
                          }}
                          {...cell.getCellProps([
                            { className: column.className },
                          ])}
                        >
                          {cell.render("Cell")}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
              {!showAggregate && (
                <TableRow>
                  <TableCell sx={{ p: 2 }} colSpan={10}>
                    <TablePagination
                      gotoPage={gotoPage}
                      rowCount={overviewDataPersistent.stats.total_count}
                      setPageSize={setPageSize}
                      pageIndex={pageIndex}
                      pageSize={pageSize}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            {showAggregate && (
              <TableFooter sx={{ borderBottomWidth: 2 }}>
                {footerGroups.map((group) => (
                  <TableRow {...group.getFooterGroupProps()}>
                    {group.headers.map((column: any) => {
                      return (
                        <TableCell
                          {...column.getFooterProps([
                            { className: column.className },
                          ])}
                        >
                          {column.render("Footer")}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableFooter>
            )}
          </Table>
        </Box>
        {showAggregate && (
          <Box sx={{ px: 2 }}>
            <TablePagination
              gotoPage={gotoPage}
              rowCount={overviewDataPersistent.stats.total_count}
              setPageSize={setPageSize}
              pageIndex={pageIndex}
              pageSize={pageSize}
            />
          </Box>
        )}
      </Stack>
    </ScrollX>
  );
};

export default AggregateTableV2;
