import { Grid } from "@mui/material";
import React from "react";

import { SelectOption } from "components/form/SelectOption";
import { TextFieldTwoDecimal } from "components/form/TextFieldWholeNumber";
import { SIZE_TYPE_OPTIONS } from "pages/deal/constants/deal_enums";

export const PropertySizeField = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextFieldTwoDecimal fieldName={"size"} displayName={"Size"} />
      </Grid>
      <Grid item xs={6}>
        <SelectOption
          fieldName={"size_type"}
          displayName={""}
          options={SIZE_TYPE_OPTIONS}
        />
      </Grid>
    </Grid>
  );
};
