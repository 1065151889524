import { FormControl, MenuItem, Select, useTheme } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import _ from "lodash";
import * as React from "react";

import { FilterComponentProps } from "components/filter/FilterBar/FilterBar";
import {
  getConditionalFilterSx,
  isFilterEmpty,
  removeEmptyFilterFromValue,
} from "pages/deal/utils/dealFilters";
import { SimpleOption } from "types/api/deal/form";
import { filterEmpty } from "types/navigation/common";

interface FilterSelectProps extends FilterComponentProps {
  label: string;
  value: string[] | string;
  onChange: (x: string[] | string) => void;
  options?: SimpleOption[];
  sx?: object;
  multiple?: boolean;
  onOpen?: (event: object) => void;
  disabled?: boolean;
}

export function FilterSelect(props: FilterSelectProps) {
  const {
    label,
    value,
    onChange,
    options = [],
    sx,
    multiple = false,
    onOpen = () => {},
    disabled = false,
  } = props;
  const theme = useTheme();

  const id = `${_.kebabCase(label)}-filter`;

  const conditionalSx = getConditionalFilterSx(value, theme);

  return (
    <FormControl sx={{ minWidth: 120, maxWidth: 200, ...sx }}>
      <Select<string[] | string>
        id={id}
        value={value}
        onChange={(e, child) => {
          const selectedValue = _.get(child, ["props", "value"]);
          if (selectedValue === filterEmpty[0]) {
            // If All was selected, clear the other values
            onChange(filterEmpty);
          } else {
            // Return all values except the empty value (for multiple, else return the value directly)
            onChange(removeEmptyFilterFromValue(e.target.value));
          }
        }}
        displayEmpty
        inputProps={{ "aria-label": label }}
        autoWidth
        multiple={multiple}
        renderValue={(x) =>
          `${label}${
            isFilterEmpty(x) || !multiple
              ? ""
              : _.isArray(x)
                ? `: ${_.map(x, (x) => _.get(_.find(options, { key: x }), "label", "")).join(", ")}`
                : `(1)`
          }`
        }
        sx={conditionalSx}
        onOpen={onOpen}
        disabled={disabled}
      >
        <MenuItem value={filterEmpty[0]}>All</MenuItem>
        {options.map(
          ({ key, label }) =>
            key && (
              <MenuItem key={key} value={key}>
                {multiple && _.isArray(value) && (
                  <Checkbox checked={_.includes(value, key)} />
                )}
                {label}
              </MenuItem>
            )
        )}
      </Select>
    </FormControl>
  );
}
