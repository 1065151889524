import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import Typography from "@mui/material/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as Sentry from "@sentry/react";
import { Form, Formik } from "formik";
import React, { useCallback, useMemo } from "react";
import { useQueryClient } from "react-query";

import { FormDialogActions } from "components/form/standard/FormDialogActions";
import { ShareContactMetadata } from "constants/objectMetadata/shareContactMetadata";
import { useUsersDisplay } from "contexts/UserDisplayContext";
import { YupRequiredFieldsProvider } from "contexts/YupRequiredFieldsContext";
import useAuth from "hooks/useAuth";
import { UserLookupFieldMultiple } from "pages/deal/components/LookupField";
import {
  getContactAccessQueryKey,
  useContactAccessQuery,
} from "queries/useContact";
import { dispatch } from "store";
import { openErrorNotification } from "store/reducers/common";
import { ContactAccessRead } from "types/api/deal/contact";
import { LookupItem } from "types/api/deal/form";
import { updateContactAccessAsync } from "utils/record_access";

import {
  deriveYupSchemaFromMetadataV2,
  getInitialValues,
  getOnSubmit,
} from "../standard/utils/metadataV2";

interface ContactAccessFormikValues {
  id: number;
  full_name: string;
  accessible_by_user_ids: LookupItem[] | null;
  accessible_by_team_ids: LookupItem[] | null;
}

interface ShareContactFormBaseProps {
  record: ContactAccessRead;
  changes?: Partial<ContactAccessFormikValues>;
  submitData: (data: any) => Promise<void>;
}

function ShareContactFormBase({
  record,
  changes = {},
  submitData,
}: ShareContactFormBaseProps) {
  const { user } = useAuth();
  const usersDisplay = useUsersDisplay();

  const initialValues = useMemo(
    () =>
      getInitialValues<ContactAccessRead, Partial<ContactAccessFormikValues>>(
        ShareContactMetadata,
        record,
        changes,
        usersDisplay,
        user
      ),
    [changes, record, user, usersDisplay]
  );
  const validationSchema = useMemo(
    () => deriveYupSchemaFromMetadataV2(ShareContactMetadata),
    []
  );
  const onSubmit = useMemo(
    () => getOnSubmit(ShareContactMetadata, submitData),
    [submitData]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={true}
      >
        <YupRequiredFieldsProvider validationSchema={validationSchema}>
          <Form>
            <Dialog open={true} scroll={"paper"} maxWidth={"xs"}>
              <DialogTitle>{`Share ${!!record.full_name ? `"${record.full_name}"` : "Contact"}`}</DialogTitle>
              <DialogContent dividers>
                <Typography>
                  Let other users access this Contact based on their
                  permissions.
                </Typography>
                <br />
                <Typography sx={{ fontWeight: 600, pb: 0.5 }}>
                  {ShareContactMetadata.accessible_by_user_ids.displayName}
                </Typography>
                <UserLookupFieldMultiple
                  fieldName={
                    ShareContactMetadata.accessible_by_user_ids.fieldName
                  }
                  displayName={
                    ShareContactMetadata.accessible_by_user_ids.displayName
                  }
                  showLabel={false}
                />
              </DialogContent>
              <FormDialogActions />
            </Dialog>
          </Form>
        </YupRequiredFieldsProvider>
      </Formik>
    </LocalizationProvider>
  );
}

export interface ShareContactFormProps {
  id?: string;
  changes?: Partial<ContactAccessFormikValues>;
  handleClose: () => void;
}

export const ShareContactForm = ({
  id,
  changes,
  handleClose,
}: ShareContactFormProps) => {
  if (!id) return null;

  const queryClient = useQueryClient();

  const submitData = useCallback(
    async (record: ContactAccessRead) => {
      try {
        const updateObj = {
          user_ids: record.accessible_by_user_ids ?? [],
          team_ids: [],
        };
        await updateContactAccessAsync(id, updateObj);
        await queryClient.invalidateQueries(getContactAccessQueryKey(id));
        handleClose();
      } catch (error) {
        dispatch(openErrorNotification("Error updating access."));
        Sentry.captureException("Unable to update record access.");
      }
    },
    [handleClose, id, queryClient]
  );

  const { data, isSuccess } = useContactAccessQuery(id);

  return (
    isSuccess && (
      <ShareContactFormBase
        submitData={submitData}
        record={data}
        changes={changes}
      />
    )
  );
};
