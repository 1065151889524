import { InfoCircleFilled } from "@ant-design/icons";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as Sentry from "@sentry/react";
import { Form, Formik, FormikConfig, useField, useFormikContext } from "formik";
import { filter, get, isNil, map, pick, size, sortBy } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";

import { RadioButtonField } from "components/form/RadioButtonField";
import { SelectOption } from "components/form/SelectOption";
import { formatStringForDisplay } from "components/form/standard/utils/formatting";
import { ContactBulkEditMetadata } from "constants/objectMetadata/contactBulkEditMetadata";
import { usePopForm } from "contexts/FormDialogsContext";
import { useUsersDisplay } from "contexts/UserDisplayContext";
import { YupRequiredFieldsProvider } from "contexts/YupRequiredFieldsContext";
import useAuth from "hooks/useAuth";
import { pluralizeWord } from "pages/deal/utils/deal";
import { formatWholeNumber } from "pages/deal/utils/reporting";
import { dispatch } from "store";
import { openErrorNotification } from "store/reducers/common";
import { ContactAccessRead } from "types/api/deal/contact";
import { ContactEditBulk, EditType } from "types/api/deal/contact_bulk";
import { contactBulkEditAsync } from "utils/record_access";

import {
  deriveYupSchemaFromMetadataV2,
  getInitialValues,
  getOnSubmit,
} from "../standard/utils/metadataV2";

interface EditContactMeta {
  is_filter: boolean;
  record_ids: number[];
  queryParams: URLSearchParams;
  count: number;
}

function isValidMeta(meta: any): meta is EditContactMeta {
  return (
    !isNil(meta) &&
    typeof meta.is_filter === "boolean" &&
    Array.isArray(meta.record_ids) &&
    meta.queryParams instanceof URLSearchParams &&
    typeof meta.count === "number"
  );
}

const CONTACT_EDIT_OPTIONS = sortBy(
  filter(
    map(ContactBulkEditMetadata, (x) => ({
      key: x.fieldName,
      label: x.displayName,
    })),
    "label"
  ),
  "label"
);

interface EditContactBulkFormikValues {
  edit_type: EditType;
  selected_field: string;
}

interface EditContactBulkFormBaseProps {
  meta: EditContactMeta;
  changes?: object;
  submitData: (data: any) => Promise<void>;
}

function EditContactBulkFormBase({
  changes = {},
  submitData,
  meta,
}: EditContactBulkFormBaseProps) {
  const { user } = useAuth();
  const usersDisplay = useUsersDisplay();
  const [step, setStep] = useState<"form" | "confirmation">("form");
  const popForm = usePopForm();

  const initialValues = useMemo(
    () =>
      getInitialValues<ContactAccessRead, Partial<EditContactBulkFormikValues>>(
        ContactBulkEditMetadata,
        undefined,
        changes,
        usersDisplay,
        user
      ),
    [changes, user, usersDisplay]
  );

  const validationSchema = useMemo(
    () => deriveYupSchemaFromMetadataV2(ContactBulkEditMetadata),
    []
  );

  const realOnSubmit = useMemo(
    () => getOnSubmit(ContactBulkEditMetadata, submitData),
    [submitData]
  );

  const onSubmit: FormikConfig<any>["onSubmit"] = useCallback(
    (values, formikHelpers) => {
      if (step === "form") {
        setStep("confirmation");
        // Wait 1s to unblock the final save button
        setTimeout(() => formikHelpers.setSubmitting(false), 1000);
      } else {
        realOnSubmit(values, formikHelpers);
      }
    },
    [step, realOnSubmit]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={true}
      >
        {(formik) => (
          <YupRequiredFieldsProvider validationSchema={validationSchema}>
            <Form>
              <Dialog open={true} scroll={"paper"} maxWidth={"xs"}>
                <DialogTitle>
                  Bulk edit {formatWholeNumber(meta.count)}{" "}
                  {pluralizeWord(meta.count, "contact", "contacts")}
                </DialogTitle>
                <DialogContent dividers>
                  {step === "form" ? (
                    <>
                      <Typography>
                        Choose a field to update across multiple contacts.
                      </Typography>
                      <br />
                      <Stack spacing={2}>
                        <Box>
                          <SelectOption
                            fieldName={"selected_field"}
                            displayName={"Field to update"}
                            options={CONTACT_EDIT_OPTIONS}
                          />
                        </Box>
                        <PolymorphicEditSection />
                      </Stack>
                    </>
                  ) : (
                    // Confirmation screen
                    <>
                      <Typography>
                        Please confirm the changes you are about to make.
                      </Typography>
                      <br />
                      <PreviewEditMessage meta={meta} />
                    </>
                  )}
                </DialogContent>
                <DialogActions>
                  {step === "form" ? (
                    <Grid
                      container
                      style={{ display: "flex" }}
                      alignItems="center"
                    >
                      <Grid item xs sx={{ flexGrow: 1 }}></Grid>
                      <Grid item>
                        {formik.submitCount > 0 && size(formik.errors) ? (
                          <Alert color="error" icon={<InfoCircleFilled />}>
                            Please correct one or more errors on the form.
                          </Alert>
                        ) : null}
                      </Grid>
                      <Grid item xs>
                        <Stack
                          direction="row"
                          justifyContent="flex-end"
                          spacing={2}
                          alignItems="center"
                          sx={{ mr: 1 }}
                        >
                          <Button
                            color="secondary"
                            onClick={popForm}
                            size={"large"}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            onClick={() => formik.handleSubmit()}
                            size={"large"}
                            variant="contained"
                            disabled={formik.isSubmitting}
                          >
                            Next
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  ) : (
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      spacing={2}
                      alignItems="center"
                      sx={{ mr: 1 }}
                    >
                      <Button
                        color="secondary"
                        onClick={popForm}
                        size={"large"}
                      >
                        Cancel
                      </Button>
                      <Button onClick={() => setStep("form")} size={"large"}>
                        Back
                      </Button>
                      <Button
                        type="submit"
                        onClick={() => formik.handleSubmit()}
                        size={"large"}
                        variant="contained"
                        disabled={formik.isSubmitting}
                      >
                        Save
                      </Button>
                    </Stack>
                  )}
                </DialogActions>
              </Dialog>
            </Form>
          </YupRequiredFieldsProvider>
        )}
      </Formik>
    </LocalizationProvider>
  );
}

const PolymorphicEditSection = () => {
  const [selectedField] = useField("selected_field");
  const [, , editTypeHelpers] = useField("edit_type");

  // Reset the value for edit_type when they change the selected field
  useEffect(() => {
    editTypeHelpers.setValue(EditType.append);
  }, [selectedField.value]);

  const fieldMetadata = get(ContactBulkEditMetadata, selectedField.value);
  if (!fieldMetadata) return null;

  const { component, isMultivalue } = fieldMetadata;

  return (
    <>
      {component}
      {isMultivalue && (
        <RadioButtonField
          fieldName={ContactBulkEditMetadata.edit_type.fieldName}
          options={[
            {
              key: EditType.append,
              label: "Append to current value(s)",
            },
            {
              key: EditType.replace,
              label: "Replace current value(s)",
            },
          ]}
          showLabel={false}
        />
      )}
    </>
  );
};

const PreviewEditMessage = ({ meta }: { meta: EditContactMeta }) => {
  const { values } = useFormikContext();

  const selectedFieldValue = get(values, "selected_field", "");
  const editTypeValue = get(values, "edit_type");

  const fieldMetadata = get(ContactBulkEditMetadata, selectedFieldValue);
  if (!fieldMetadata) return null;

  const {
    isMultivalue,
    displayName,
    displayNamePlural = "MISSING",
    formatForDisplay = formatStringForDisplay,
  } = fieldMetadata;

  const fieldValue = get(values, selectedFieldValue);

  return isMultivalue ? (
    <>
      <Typography>
        The following <b>{displayNamePlural}</b> will{" "}
        {editTypeValue === EditType.append ? (
          <>
            be <b>added</b> to
          </>
        ) : (
          <b>replace</b>
        )}{" "}
        the existing {displayNamePlural} for{" "}
        <b>
          {meta.count} {pluralizeWord(meta.count, "contact", "contacts")}
        </b>
        .
      </Typography>
      <ul>
        {fieldValue ? (
          <b>{formatForDisplay(fieldValue)}</b>
        ) : (
          "No value selected"
        )}
      </ul>
    </>
  ) : (
    <Typography>
      The <b>{displayName}</b> field will be updated to{" "}
      <b>{formatForDisplay(fieldValue)}</b> for{" "}
      <b>
        {formatWholeNumber(meta.count)}{" "}
        {pluralizeWord(meta.count, "contact", "contacts")}
      </b>
      .
    </Typography>
  );
};

export interface EditContactBulkFormProps {
  id?: string;
  changes?: object;
  handleClose: () => void;
  successCallback: any;
  meta?: any;
}

interface ContactAccessBulkUpdate {
  edit_type: EditType;
  selected_field: string;
}

export const EditContactBulkForm = ({
  changes,
  handleClose,
  successCallback,
  meta,
}: EditContactBulkFormProps) => {
  const queryClient = useQueryClient();

  if (!isValidMeta(meta)) return null;

  const submitData = useCallback(
    async (record: ContactAccessBulkUpdate) => {
      try {
        const selectedFieldMetadata =
          ContactBulkEditMetadata[record.selected_field];
        const { submitFieldName, fieldName } = selectedFieldMetadata;
        const fieldKey = submitFieldName || fieldName;

        const updateObj: ContactEditBulk = {
          edit_type: record.edit_type,
          is_filter: meta.is_filter,
          record_ids: meta.record_ids,
          data: pick(record, fieldKey),
        };
        await contactBulkEditAsync(updateObj, meta.queryParams);
        await queryClient.invalidateQueries();
        if (typeof successCallback === "function") successCallback();
        handleClose();
      } catch (error) {
        dispatch(openErrorNotification("Error updating records."));
        Sentry.captureException("Unable to bulk edit contacts.");
      }
    },
    [meta, handleClose, queryClient, successCallback]
  );

  return (
    <EditContactBulkFormBase
      submitData={submitData}
      changes={changes}
      meta={meta}
    />
  );
};
