import _, { isEqual } from "lodash";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";

import { FilterAutocomplete } from "components/filter/FilterBar/FilterComponents/Autocomplete/FilterAutocomplete";
import { FilterAutocompleteLookupProps } from "components/filter/FilterBar/FilterComponents/Autocomplete/FilterAutocompleteLookup";
import { getBaseQueryOptionsDealService } from "pages/deal/utils/api";
import { parseFiltersAsParamsObject } from "pages/deal/utils/deal";
import { isFilterEmpty } from "pages/deal/utils/dealFilters";
import { SimpleOptionGrouped } from "types/api/deal/form";
import { filterEmpty } from "types/navigation/common";
import { StageFilter } from "types/navigation/stageFilter";

export function FilterAutocompleteStageLookup(
  props: FilterAutocompleteLookupProps
) {
  const { label, value, onChange, sx, values } = props;

  const [optionsPersistent, setOptionsPersistent] = useState<
    SimpleOptionGrouped[]
  >([]);

  const queryParams = useMemo(
    () =>
      parseFiltersAsParamsObject<StageFilter>({
        deal_type: _.get(values, "deal_type", filterEmpty),
      }),
    [values]
  );

  const dealStagesQueryOptions = useMemo(
    () =>
      getBaseQueryOptionsDealService<SimpleOptionGrouped[]>(
        "/form/stage",
        queryParams
      ),
    [queryParams]
  );
  const { data: options = [], isLoading } = useQuery<SimpleOptionGrouped[]>(
    dealStagesQueryOptions
  );

  useEffect(() => {
    // Persist the options in between loads. This is to counteract the "stale-while-revalidating" logic from react-query.
    if (!isLoading) {
      setOptionsPersistent(options);
    }
  }, [isLoading, options]);

  return (
    <FilterAutocomplete
      label={label}
      value={value}
      onChange={onChange}
      options={optionsPersistent}
      sx={sx}
      isLoading={isLoading}
      grouped
    />
  );
}
