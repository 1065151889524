import config from "config";
import prospects from "menu-items/prospects";
import { NavItemType } from "types/menu";

import back_office from "./back_office";
import deal_view from "./deal_view";

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [prospects, deal_view],
};

if (config.features.back_office) {
  menuItems.items.push(back_office);
}

export default menuItems;
