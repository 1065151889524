import { FileTextOutlined } from "@ant-design/icons";
import BarChartIcon from "@mui/icons-material/BarChart";
import { red } from "@mui/material/colors";
import { FormattedMessage } from "react-intl";

import { Product } from "types/license";
import { NavItemType } from "types/menu";

// ==============================|| MENU ITEMS - BACK OFFICE ||============================== //

const notificationCount = 5;
const back_office: NavItemType = {
  id: "group-back-office",
  title: <FormattedMessage id="back-office" />,
  type: "group",
  products: [Product.back_office],
  children: [
    {
      id: "bo-vouchers",
      title: <FormattedMessage id="bo-vouchers" />,
      type: "item",
      breadcrumbs: false,
      url: "/back-office/vouchers/overview",
      icon: FileTextOutlined,
      ...(notificationCount > 0 && {
        chip: {
          color: "primary",
          variant: "filled",
          size: "small",
          label: <span>{notificationCount}</span>,
          style: {
            backgroundColor: red[400],
            color: "#ffffff",
          },
        },
      }),
    },
    {
      id: "bo-invoices",
      title: <FormattedMessage id="bo-invoices" />,
      type: "item",
      breadcrumbs: false,
      url: "/back-office/invoices/overview",
      icon: FileTextOutlined,
    },
    {
      id: "bo-uikit",
      title: <FormattedMessage id="bo-uikit" />,
      type: "item",
      breadcrumbs: false,
      url: "/back-office/uikit",
      icon: FileTextOutlined,
    },
    {
      id: "bo-reports",
      title: <FormattedMessage id="bo-reports" />,
      type: "item",
      url: "/back-office/reports/overview",
      icon: BarChartIcon,
    },
  ],
};

export default back_office;
