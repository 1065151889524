import { dispatch } from "store";
import { openErrorNotification } from "store/reducers/common";
import { Invoice } from "types/api/deal/invoice";
import { dealService } from "utils/axios";
import { handleRecordActionResponseV4 } from "utils/record";

export async function createInvoiceAsync(invoice: Invoice) {
  try {
    const response = await dealService.post(`/invoice/`, invoice);
    handleRecordActionResponseV4(response);
    return response.data;
  } catch (error) {
    dispatch(openErrorNotification("Error creating invoice."));
    throw error;
  }
}

export async function updateInvoiceAsync(
  id: string,
  invoice: Partial<Invoice>
) {
  try {
    const response = await dealService.put(`/invoice/${id}`, invoice);
    handleRecordActionResponseV4(response);
    return response.data;
  } catch (error) {
    dispatch(openErrorNotification("Error updating invoice."));
    throw error;
  }
}

export async function deleteInvoiceAsync(id: string) {
  try {
    const response = await dealService.delete(`/invoice/${id}`);
    handleRecordActionResponseV4(response);
  } catch (error) {
    dispatch(openErrorNotification("Error deleting invoice."));
    throw error;
  }
}
