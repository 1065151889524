import { Button, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import React, { ErrorInfo, ReactNode } from "react";

import BrowserError from "components/illustrations/BrowserError";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    Sentry.captureException("ErrorBoundary Triggered", {
      extra: {
        error: error,
        componentStack: info.componentStack,
      },
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "16px",
          }}
          role="alert"
        >
          <BrowserError style={{ marginBottom: "16px" }} />
          <Typography variant="h4">Oh no! An error has occurred.</Typography>
          <Typography>
            We've sent a report to our app development team to let them know
            you've had an issue.
          </Typography>
          <Typography>
            If the problem persists, please contact support.
          </Typography>
          <Button
            variant="outlined"
            color="info"
            onClick={() => {
              window.location.href = "/";
            }}
            style={{ marginTop: "16px" }}
          >
            Return to home
          </Button>
        </div>
      );
    }

    return this.props.children;
  }
}
