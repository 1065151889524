import {
  AppPrice,
  AppProduct,
  Role,
  User,
} from "types/api/user_management/user";

export enum PriceType {
  RECURRING = "recurring",
  ONE_TIME = "one_time",
}

export enum TiersMode {
  GRADUATED = "graduated",
  VOLUME = "volume",
  THRESHOLD = "threshold",
  PER_UNIT = "per_unit",
}

export enum BillingScheme {
  PER_UNIT = "per_unit",
  TIERED = "tiered",
  TIERED_WITH_REGULAR = "tiered_with_regular",
  TIERED_WITH_REGULAR_PERCENT = "tiered_with_regular_percent",
}

export enum RecurringInterval {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  YEAR = "year",
}

export enum RecurringUsageType {
  LICENSED = "licensed",
  METERED = "metered",
}

export enum TaxBehavior {
  EXCLUSIVE = "exclusive",
  INCLUSIVE = "inclusive",
  UNSPECIFIED = "unspecified",
}

export interface ProductBase {
  external_id: string;
  name?: string | null;
  active: boolean;
  default_price?: string | null;
  description?: string | null;
  livemode: boolean;
  package_dimensions_id?: number | null;
  shippable?: boolean | null;
  statement_descriptor?: string | null;
  tax_code?: string | null;
  unit_label?: string | null;
  url?: string | null;
}

export interface Product extends ProductBase {
  id: number;
  price: Price;
  package_dimensions: PackageDimensions;
  app_product: AppProduct;
}

export interface PackageDimensions {
  id: number;
  height: number;
  length: number;
  weight: number;
  width: number;
}

export interface PriceBase {
  external_id: string;
  product_id?: number | null;
  active: boolean;
  billing_scheme: BillingScheme;
  currency: string;
  custom_unit_amount?: string | null;
  livemode: boolean;
  lookup_key?: string | null;
  nickname?: string | null;
  recurring_aggregate_usage?: string | null;
  recurring_interval?: RecurringInterval | null;
  recurring_interval_count: number;
  recurring_usage_type?: RecurringUsageType | null;
  tax_behavior?: TaxBehavior | null;
  tiers_mode?: TiersMode | null;
  transform_quantity?: string | null;
  type: PriceType;
  unit_amount: number;
  unit_amount_decimal: string;
}

export interface Price extends PriceBase {
  id: number;
  product_id?: number | null;
  subscription_item: SubscriptionItem;
  app_price: AppPrice;
}

export interface SubscriptionItemBase {
  external_id: string;
  price_id?: number | null;
  quantity: number;
  subscription_id?: number | null;
}

export interface SubscriptionItem extends SubscriptionItemBase {
  id: number;
  price_id?: number | null;
  subscription_id?: number | null;
  price: Price;
  subscription: Subscription;
}

export interface SubscriptionBase {
  external_id?: string | null;
  expire_date: string; // You may need to adjust this to match Luxon's format
  trial_end_date?: string | null; // You may need to adjust this to match Luxon's format
}

export interface Subscription extends SubscriptionBase {
  id: number;
  organization_id?: number | null;
  subscription_item: SubscriptionItem[];
  organization: Organization;
  discount: Discount;
}

export interface CouponBase {
  amount_off?: number | null;
  duration_in_months?: number | null;
  name?: string | null;
  percent_off?: number | null;
}

export interface Coupon extends CouponBase {
  id: number;
  external_id?: string | null;
  discount_id?: number | null;
  discount: Discount;
}

export interface Discount {
  id: number;
  external_id?: string | null;
  subscription_id?: number | null;
  end?: number | null;
  start?: number | null;
  subscription: Subscription;
  coupon: Coupon;
}

export interface OrganizationBase {
  name: string;
  stripe_customer_id?: string | null;
  theme?: string | null;
}

export interface Organization extends OrganizationBase {
  id: number;
  subscription: Subscription;
  hierarchy: Hierarchy;
  user: User;
  role_owner: Role[];
}

export interface HierarchyBase {
  name: string;
  parent_team_id?: number | null;
  organization_id?: number | null;
}

export interface Hierarchy extends HierarchyBase {
  id: number;
  parent_team?: Hierarchy;
  children_team: Hierarchy[];
  team_member: User;
  organization: Organization;
  assigned_role: Role;
}

export enum LookupUserScope {
  organization = "organization",
  team = "team",
  individual = "individual",
}
