/**
 * Compares two floating-point numbers for equality, allowing for precision differences.
 *
 * @param num1 - The first number to compare.
 * @param num2 - The second number to compare.
 * @param epsilon - The precision tolerance (defaults to 0.000001).
 * @returns True if the numbers are approximately equal, otherwise false.
 */
export const areFloatsEqual = (
  num1: number,
  num2: number,
  epsilon: number = 0.000001
): boolean => {
  return Math.abs(num1 - num2) < epsilon;
};
