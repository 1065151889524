import {
  ContactDeleteBulk,
  ContactEditBulk,
  ContactShare,
  ContactShareBulk,
} from "types/api/deal/contact_bulk";
import { dealService } from "utils/axios";
import { openNotification } from "utils/notistack";

export async function updateContactAccessAsync(
  contact_id: string,
  data: ContactShare
) {
  const response = await dealService.post(
    `/access/contact/${contact_id}`,
    data
  );
  openNotification(`Record access updated.`, "success");
  return response.data;
}

export async function updateContactAccessBulkAsync(
  data: ContactShareBulk,
  queryParams: URLSearchParams
) {
  const response = await dealService.post(`/contact/bulk_share`, data, {
    params: queryParams,
  });
  openNotification(`Record access updated.`, "success");
  return response.data;
}

export async function contactBulkEditAsync(
  data: ContactEditBulk,
  queryParams: URLSearchParams
) {
  const response = await dealService.post(`/contact/bulk_edit`, data, {
    params: queryParams,
  });
  openNotification(`Records updated.`, "success");
  return response.data;
}

export async function contactBulkDeleteAsync(
  data: ContactDeleteBulk,
  queryParams: URLSearchParams
) {
  const response = await dealService.post(`/contact/bulk_delete`, data, {
    params: queryParams,
  });
  openNotification(`Records deleted.`, "success");
  return response.data;
}
