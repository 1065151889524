import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { FieldArray, useField, useFormikContext } from "formik";
import { sumBy } from "lodash";
import React from "react";

import { TextFieldCurrency } from "components/form/TextFieldCurrency";
import { TextFieldPercent } from "components/form/TextFieldPercent";
import { formatCurrency } from "pages/deal/utils/reporting";
import { LookupItem } from "types/api/deal/form";
import {
  DetailTypeEnum,
  TransactionDetail,
} from "types/api/deal/transaction_detail";

const DETAIL_TYPE_ITEMS: LookupItem[] = [
  { key: DetailTypeEnum.RENT, label: "Rent" },
  { key: DetailTypeEnum.FREE_RENT, label: "Free Rent" },
  { key: DetailTypeEnum.EXPENSE, label: "Expense" },
  { key: DetailTypeEnum.SALE, label: "Sale" },
  { key: DetailTypeEnum.FEE, label: "Fee" },
  { key: DetailTypeEnum.OTHER, label: "Other" },
];

const TransactionDetailsFieldArray: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<{
    transaction_details: TransactionDetail[];
  }>();

  // Calculate totals for Total Rent and Commission Amount
  const totalRent = sumBy(values.transaction_details, "total_amount");
  const totalCommission = sumBy(
    values.transaction_details,
    "commission_amount"
  );

  return (
    <FieldArray
      name="transaction_details"
      render={({ remove, push }) => {
        return (
          <>
            <TableContainer>
              <Table sx={{ minWidth: 800 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Months</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>Rent/Month</TableCell>
                    <TableCell>Total Rent</TableCell>
                    <TableCell>Commission %</TableCell>
                    <TableCell>Commission Amount</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {values.transaction_details.map((detail, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>

                      {/* Type Field */}
                      <TableCell>
                        <Select
                          fullWidth
                          value={detail.detail_type || ""}
                          onChange={(e) =>
                            setFieldValue(
                              `transaction_details[${index}].detail_type`,
                              e.target.value
                            )
                          }
                        >
                          {DETAIL_TYPE_ITEMS.map((item) => (
                            <MenuItem key={item.key} value={item.key}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>

                      {/* Months (Quantity) */}
                      <TableCell>
                        <TextField
                          fullWidth
                          type="number"
                          value={detail.quantity || ""}
                          onChange={(e) =>
                            setFieldValue(
                              `transaction_details[${index}].quantity`,
                              parseInt(e.target.value, 10)
                            )
                          }
                        />
                      </TableCell>

                      {/* Start Date */}
                      <TableCell>
                        <TextField
                          fullWidth
                          type="date"
                          value={detail.date_start || ""}
                          onChange={(e) =>
                            setFieldValue(
                              `transaction_details[${index}].date_start`,
                              e.target.value
                            )
                          }
                        />
                      </TableCell>

                      {/* End Date */}
                      <TableCell>
                        <TextField
                          fullWidth
                          type="date"
                          value={detail.date_end || ""}
                          onChange={(e) =>
                            setFieldValue(
                              `transaction_details[${index}].date_end`,
                              e.target.value
                            )
                          }
                        />
                      </TableCell>

                      {/* Rent/Month */}
                      <TableCell>
                        <TextFieldCurrency
                          fieldName={`transaction_details.${index}.total_per_quantity`}
                          showLabel={false}
                        />
                      </TableCell>

                      {/* Total Rent */}
                      <TableCell>
                        <TextFieldCurrency
                          fieldName={`transaction_details.${index}.total_amount`}
                          showLabel={false}
                        />
                      </TableCell>

                      {/* Commission % */}
                      <TableCell>
                        <TextFieldPercent
                          fieldName={`transaction_details.${index}.commission_percent`}
                          showLabel={false}
                        />
                      </TableCell>

                      {/* Commission Amount */}
                      <TableCell>
                        <TextFieldCurrency
                          fieldName={`transaction_details.${index}.commission_amount`}
                          showLabel={false}
                        />
                      </TableCell>

                      {/* Action */}
                      <TableCell align="center">
                        <Button
                          color="error"
                          startIcon={<DeleteOutlined />}
                          onClick={() => remove(index)}
                        >
                          Remove
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                {/* Footer for totals */}
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={6} align="right">
                      <strong>Totals:</strong>
                    </TableCell>
                    <TableCell align="right">
                      <Typography color={"textPrimary"} fontWeight={600}>
                        {formatCurrency(totalRent)}
                      </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right">
                      <Typography color={"textPrimary"} fontWeight={600}>
                        {formatCurrency(totalCommission)}
                      </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
            <Grid container justifyContent="space-between">
              <Grid item xs={12} md={8}>
                <Box sx={{ pt: 2.5, pr: 2.5, pb: 2.5, pl: 0 }}>
                  <Button
                    color="primary"
                    startIcon={<PlusOutlined />}
                    onClick={() =>
                      push({
                        detail_type: "",
                        quantity: 0,
                        date_start: "",
                        date_end: "",
                        total_amount: 0,
                        commission_percent: 0,
                        commission_amount: 0,
                      })
                    }
                    variant="dashed"
                    sx={{ bgcolor: "transparent !important" }}
                  >
                    Add Item
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </>
        );
      }}
    />
  );
};

export default TransactionDetailsFieldArray;
