import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import {
  isFeatureEnabledOrgPreferences,
  isFieldVisibleOrgPreferences,
  useDealOrgPreferences,
} from "contexts/DealOrgPreferencesContext";
import { DealFeature, DealField } from "types/deal";
import {
  DateRangeFilterType,
  DateRangeFilterTypeDisplay,
} from "types/navigation/dealNavigation";

export function DateRangeFilterTypeSelect(props: {
  value: any;
  onChange: (x: DateRangeFilterType) => void;
  disabled?: boolean;
}) {
  const { data: dealOrgPreferences } = useDealOrgPreferences();

  return (
    <FormControl sx={{ width: 220 }}>
      <InputLabel id="date-range-filter-type-label">
        Date Range Filters On:
      </InputLabel>
      <Select<DateRangeFilterType>
        labelId="date-range-filter-type-label"
        id="date-range-filter-type"
        value={props.value}
        onChange={(e) => props.onChange(e.target.value as DateRangeFilterType)}
        displayEmpty
        autoWidth
        sx={{
          "& .MuiInputBase-input": {
            fontWeight: 600,
          },
        }}
        disabled={props.disabled}
      >
        <MenuItem
          key={DateRangeFilterType.close_date}
          value={DateRangeFilterType.close_date}
        >
          {DateRangeFilterTypeDisplay[DateRangeFilterType.close_date]}
        </MenuItem>

        {isFeatureEnabledOrgPreferences(
          dealOrgPreferences,
          DealFeature.COMMISSION_PAYMENTS
        ) && (
          <MenuItem
            key={DateRangeFilterType.commission_due_date}
            value={DateRangeFilterType.commission_due_date}
          >
            {
              DateRangeFilterTypeDisplay[
                DateRangeFilterType.commission_due_date
              ]
            }
          </MenuItem>
        )}

        {isFieldVisibleOrgPreferences(
          dealOrgPreferences,
          DealField.LEASE_EXPIRATION
        ) && (
          <MenuItem
            key={DateRangeFilterType.lease_expiration}
            value={DateRangeFilterType.lease_expiration}
          >
            {DateRangeFilterTypeDisplay[DateRangeFilterType.lease_expiration]}
          </MenuItem>
        )}

        {isFieldVisibleOrgPreferences(
          dealOrgPreferences,
          DealField.HIRE_DATE
        ) && (
          <MenuItem
            key={DateRangeFilterType.hire_date}
            value={DateRangeFilterType.hire_date}
          >
            {DateRangeFilterTypeDisplay[DateRangeFilterType.hire_date]}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
}
