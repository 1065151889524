import * as Yup from "yup";

import { TextFieldString } from "components/form/TextFieldString";
import { baseDealFilters } from "pages/deal/constants/dealFilters";
import {
  parseFiltersAsParamsObject,
  parseParamsAsFilters,
} from "pages/deal/utils/deal";
import { ObjectMetadataV3 } from "types/standardFormV3";

export const ReportMetadata: ObjectMetadataV3 = {
  name: {
    fieldName: "name",
    displayName: "Report Name",
    _schema: Yup.string().required("Report Name is required."),
    initialValue: "",
  },
  entity_type: {
    fieldName: "entity_type",
    displayName: "Entity Type",
    _schema: Yup.string()
      .oneOf(["deal", "contact", "activity"]) // Replace with actual EntityEnum values
      .required("Entity Type is required."),
    initialValue: "",
  },
  report_type: {
    fieldName: "report_type",
    displayName: "Report Type",
    _schema: Yup.string()
      .oneOf(["aggregate_report"]) // Based on the ReportTypeEnum
      .required("Report Type is required."),
    initialValue: "aggregate_report",
  },
  report_logic: {
    fieldName: "report_logic",
    displayName: "Report Logic",
    _schema: Yup.object(), // Assuming a generic object schema
    initialValue: {},
  },
  report_filter: {
    fieldName: "report_filter",
    displayName: "Report Filter",
    _schema: Yup.object(), // Assuming a generic object schema
    initialValue: baseDealFilters,
  },
};
