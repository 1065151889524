import * as Yup from "yup";

import { TextFieldString } from "components/form/TextFieldString";
import { InvoiceObjectMetadataV3 } from "types/standardFormV3";

export const InvoiceBaseMetadata: InvoiceObjectMetadataV3 = {
  name: {
    fieldName: "name",
    displayName: "Invoice Name",
    _schema: Yup.string().required("Invoice Name is required."),
    component: TextFieldString,
    initialValue: "",
  },
};
